import { object, number, string, boolean } from "yup";

export const validationSchema = object().shape({
  company_id: string().required(
    "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"
  ),
  company_name: string().required(
    "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"
  ),
  company_main_timezone: string().required(
    "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"
  ),
  company_fiscal_year_type: boolean().required(
    "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"
  ),
  company_fiscal_year_start_month: number().required(
    "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"
  )
});
