import { useIntl } from "react-intl";
import PermissionsHeader from "./components/PermissionsHeader";
import PermissionsBodyWrapper from "./components/PermissionsBodyWrapper";

const PermissionsWrapper = () => {
  const intl = useIntl();
  return (
    <div className="card mb-5 mb-xxl-8">
      <PermissionsHeader />
      <div className="card-body">
        <PermissionsBodyWrapper />
      </div>
    </div>
  );
};

export default PermissionsWrapper;
