import RolesWrapper from "./roles/RolesWrapper";
import PermissionsWrapper from "./permissions/PermissionsWrapper";
import PageHeader from "../../layout/components/content/PageHeader";
import { RolesAndPermissionsProvider, useRolesView } from "./core/RolesAndPermissionsProvider";
import { RolesModal } from "./roles/modals/RolesModal";
import { useIntl } from "react-intl";
import { FC } from "react";

const RolesAndPermissions = () => {
  const { roleIdForUpdate } = useRolesView();

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7 pl-3">
        <PageHeader
          level1name="SIDEBAR.HEADER.USERMGMT"
          level1link="/app/home"
          level2name="ROLES.HEADER"
          instructions="ROLES.HEADER.INSTRUCTIONS"
        />
      </div>
      <div className="flex-column-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xl-5">
              <RolesWrapper />
            </div>
            <div className="col-12 col-xl-7">
              <PermissionsWrapper />
            </div>
          </div>
        </div>
      </div>

      {roleIdForUpdate.id !== undefined && roleIdForUpdate.type !== "EDIT_ROLE" && <RolesModal />}
    </>
  );
};

const RolesAndPermissionsWrapper: FC = () => {
  const intl = useIntl();
  return (
    <RolesAndPermissionsProvider>
      <RolesAndPermissions />
    </RolesAndPermissionsProvider>
  );
};

export { RolesAndPermissionsWrapper };
