import { Form as BootstrapForm } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import styles from "../style/PasswordField.module.css";

import { useState } from "react";
import { hide } from "@popperjs/core";

type PasswordFieldProps = {
  id: string;
  label: string;
  isInvalid?: boolean;
  description?: string;
  optional?: boolean;
  classes?: string;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  disabled?: boolean;
  hideShowPasswordIcon?: boolean;
};

/**
 * Password/text type field component that uses Formik's Field component to render a password input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} [label=""] - The label for the field
 * @prop {boolean} [isInvalid=false] - Whether the field is invalid or not
 * @prop {string} [description] - The description for the field
 * @prop {boolean} [optional=false] - Whether the field is optional or not
 * @prop {string} [classes] - Any additional classes to apply to the field
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {boolean} [disabled=false] - Whether the field is disabled or not
 * @prop {boolean} [hideShowPasswordIcon=false] - Whether to hide the show password icon or not
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const PasswordField = ({
  id,
  label,
  isInvalid = false,
  optional = false,
  description,
  classes,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  disabled = false,
  hideShowPasswordIcon = false,
  ...props
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>{`${label}${optional ? " (optional)" : ""}`}</BootstrapForm.Label>
      )}
      <div className={styles.passwordFieldContainer}>
        <Field
          type={showPassword ? "text" : "password"}
          id={id}
          name={id}
          as={BootstrapForm.Control}
          isInvalid={isInvalid}
          className={`form-input ${styles.pr30px} ${classes}`}
          disabled={disabled}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
          {...props}
        />
        {hideShowPasswordIcon || (
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className={styles.showPasswordIcon}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default PasswordField;
