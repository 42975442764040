/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useThemeMode } from "../../../../layout/partials";
import { toAbsoluteUrl } from "../../../../helpers";
import { Link } from "react-router-dom";

const InvalidVerificationCode = () => {
  const intl = useIntl();
  const { mode } = useThemeMode();
  useEffect(() => {
    document.body.style.backgroundImage =
      mode === "dark"
        ? `url(${toAbsoluteUrl("/media/auth/bg4-dark.jpg")})`
        : `url(${toAbsoluteUrl("/media/auth/bg4.jpg")})`;
  }, [mode]);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="d-flex flex-column flex-center p-10">
          <div className="card card-flush  w-lg-650px py-5">
            <div className="card-body">
              <div className="pb-10">
                <h2>{intl.formatMessage({ id: "AUTH.RESETPASSWORD.INVALID.TITLE" })}</h2>
                <p>
                  {intl.formatMessage({ id: "AUTH.RESETPASSWORD.EXPIRED.DESCRIPTIOn" })}
                </p>
                <Link to="/auth/login" className="btn btn-primary">{intl.formatMessage({ id: "AUTH.BACKTOLOGIN" })}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidVerificationCode;
