import React from 'react';
import { Container } from 'react-bootstrap';
import DependenciesTable from '../../../layout/components/tables/DependenciesTable';
import PageHeader from '../../../layout/components/content/PageHeader';

const DependenciesAcknowledgement: React.FC = () => {
  return (
    <Container className="form-container">
      <div className="w-100">
        <PageHeader
          level1name="SETTINGS.ACK.TITLE"
          instructions="SETTINGS.ACK.INSTRUCTIONS"
        />
        <DependenciesTable />
      </div>
    </Container>
  );
};

export default DependenciesAcknowledgement;
