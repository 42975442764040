import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';

// Firebase configuration object
const firebaseConfig: { apiKey: string | undefined; authDomain: string | undefined } = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'insentric-root.firebaseapp.com',
};

// Ensure required environment variables are present
if (!firebaseConfig.apiKey) {
  throw new Error('Missing Firebase API Key in environment variables!');
}

if (!firebaseConfig.authDomain) {
  throw new Error('Missing Firebase Auth Domain in environment variables!');
}

// Initialize Firebase app within a block
const firebaseApp: FirebaseApp = (() => {
  try {
    return initializeApp(firebaseConfig);
  } catch (error) {
    console.error("Firebase initialization error", error);
    throw error; // rethrow the error after logging it
  }
})();

// Initialize Firebase Auth
export const auth: Auth = getAuth(firebaseApp);

// Default export for Firebase app
export default firebaseApp;
