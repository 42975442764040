import { Form as BootstrapForm } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

type NumberFieldProps = {
  id: string;
  label: string;
  description?: string;
  optional?: boolean;
  disabled?: boolean;
  classes?: string;
  min?: number;
  max?: number;
  placeholder?: number;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Number type field component that uses Formik's Field component to render a number input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} label - The label for the field
 * @prop {string} [description] - The description for the field
 * @prop {boolean} [optional=false] - Whether the field is optional or not
 * @prop {boolean} [disabled=false] - Whether the field is disabled or not
 * @prop {string} [classes] - Any additional classes to apply to the field
 * @prop {number} [min] - The minimum value for the field
 * @prop {number} [max] - The maximum value for the field
 * @prop {number} [placeholder] - The placeholder for the field 
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const NumberField = ({
  id,
  label,
  description,
  optional = false,
  disabled = false,
  classes,
  min,
  max,
  placeholder,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: NumberFieldProps) => {
  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>{`${label}${optional ? " (optional)" : ""}`}</BootstrapForm.Label>
      )}
      <Field
        type="number"
        min={min}
        max={max}
        id={id}
        name={id}
        as={BootstrapForm.Control}
        className={`form-input ${classes}`}
        disabled={disabled}
        placeholder={placeholder}
        aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
        aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
        {...props}
      />
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default NumberField;
