import React, { useState, useEffect } from "react";
import axios, { all } from "axios";
import * as Yup from "yup";
import { Container, NavLink, Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import Form from "../../../layout/components/form/Form";
import { PasswordField } from "../../../layout/components/form";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import PageHeader from "../../../layout/components/content/PageHeader";
import { useRBAC } from "../../roles-permissions/core/RBACProvide";
import { useAuth } from "../../auth/core/Auth";

const Secrets: React.FC = () => {
  const { allowedUserButtonClick, allowedUserInputEdit } = useRBAC();
  const { currentUser } = useAuth();

  console.log("currentUser", currentUser?.roles?.[0]?.id);

  const [isSfdcEnabled, setIsSfdcEnabled] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({
    salesforce: undefined,
    marketo: undefined
  });

  const intl = useIntl();

  // Fetch initial configuration data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const terraformResponse = await axios.get("/configs/terraform");
        if (terraformResponse.data.sfdc_organization_id) {
          setIsSfdcEnabled(true);
        }

        if (currentUser?.roles?.[0]?.id === "Demo User") {
          setInitialValues({
            marketo: {
              mkto_munchkin_id: "",
              mkto_client_id: "",
              mkto_client_secret: ""
            },
            salesforce: {
              sfdc_login_url: "",
              sfdc_username: "",
              sfdc_password: "",
              sfdc_token: ""
            }
          });
        } else {
          const secretsResponse = await axios.get("/secrets");
          const data = secretsResponse.data;
          setInitialValues({
            marketo: {
              mkto_munchkin_id: data.mkto_munchkin_id,
              mkto_client_id: data.mkto_client_id,
              mkto_client_secret: data.mkto_client_secret
            },
            salesforce: {
              sfdc_login_url: data.sfdc_login_url,
              sfdc_username: data.sfdc_username,
              sfdc_password: data.sfdc_password,
              sfdc_token: data.sfdc_token
            }
          });
        }
      } catch (err) {
        console.error("Error fetching initial values:", err);
      }
    };
    fetchInitialData();
  }, []);

  const handleSubmit = async (platform: any, values: any, { setSubmitting }: { setSubmitting: any }) => {
    setSubmitting(true);

    let secrets;

    switch (platform) {
      case "marketo":
        secrets = {
          MRKT_CLIENT_ID: values.mkto_client_id,
          MRKT_CLIENT_SECRET: values.mkto_client_secret
        };
        break;

      case "salesforce":
        secrets = {
          SFDC_LOGIN_URL: values.sfdc_login_url,
          SFDC_USERNAME: values.sfdc_username,
          SFDC_PASSWORD: values.sfdc_password,
          SFDC_TOKEN: values.sfdc_token
        };
        break;

      default:
        console.error("Invalid platform specified for secrets update");
        toast.error(intl.formatMessage({ id: "SECRETS.TOAST.ERROR" }), toastSettings("error"));
        setSubmitting(false);
        return;
    }

    try {
      const response = await axios.post(`/secrets/test/${platform}`, secrets);

      if (response.status !== StatusCodes.OK) {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      toast.error(
        `${platform} ${intl.formatMessage({
          id: "SECRETS.TOAST.ERROR.TEST"
        })}: ${error.message}`,
        toastSettings("error")
      );
      setSubmitting(false);
      return;
    }

    try {
      await axios.post("/secrets", secrets);

      toast.success(`${platform} ${intl.formatMessage({ id: "SECRETS.TOAST.SUCCESS" })}`, toastSettings("success"));
    } catch (err: any) {
      toast.error(
        `${intl.formatMessage({
          id: "SECRETS.TOAST.ERROR"
        })} for ${platform}: ${err.message}`,
        toastSettings("error")
      );
    }
  };

  const fields: any = {
    marketo: [
      {
        label: intl.formatMessage({ id: "SECRETS.MARKETO.MUNCHKINID" }),
        id: "mkto_munchkin_id",
        initialValue: undefined,
        validationSchema: Yup.string().matches(/([\d]{3})-([A-Za-z]{3})-([\d]{3})/),
        optional: false,
        disabled: true,
        fieldComponent: PasswordField,
        hideShowPasswordIcon: true
      },
      {
        label: intl.formatMessage({ id: "SECRETS.MARKETO.CLIENTID" }),
        id: "mkto_client_id",
        initialValue: undefined,
        validationSchema: Yup.string().uuid(),
        optional: false,
        fieldComponent: PasswordField,
        disabled: !allowedUserInputEdit,
        hideShowPasswordIcon: !allowedUserInputEdit
      },
      {
        label: intl.formatMessage({ id: "SECRETS.MARKETO.CLIENTSECRET" }),
        id: "mkto_client_secret",
        initialValue: undefined,
        validationSchema: Yup.string(),
        optional: false,
        fieldComponent: PasswordField,
        disabled: !allowedUserInputEdit,
        hideShowPasswordIcon: !allowedUserInputEdit
      }
    ],
    salesforce: [
      {
        label: intl.formatMessage({ id: "SECRETS.SFDC.LOGINURL" }),
        id: "sfdc_login_url",
        initialValue: undefined,
        validationSchema: Yup.string().url(),
        optional: false,
        fieldComponent: PasswordField,
        disabled: !allowedUserInputEdit,
        hideShowPasswordIcon: !allowedUserInputEdit
      },
      {
        label: intl.formatMessage({ id: "SECRETS.SFDC.USERNAME" }),
        id: "sfdc_username",
        initialValue: undefined,
        validationSchema: Yup.string(),
        optional: false,
        fieldComponent: PasswordField,
        disabled: !allowedUserInputEdit,
        hideShowPasswordIcon: !allowedUserInputEdit
      },
      {
        label: intl.formatMessage({ id: "SECRETS.SFDC.PASSWORD" }),
        id: "sfdc_password",
        initialValue: undefined,
        validationSchema: Yup.string(),
        optional: false,
        fieldComponent: PasswordField,
        disabled: !allowedUserInputEdit,
        hideShowPasswordIcon: !allowedUserInputEdit
      },
      {
        label: intl.formatMessage({ id: "SECRETS.SFDC.TOKEN" }),
        id: "sfdc_token",
        initialValue: undefined,
        validationSchema: Yup.string(),
        optional: false,
        fieldComponent: PasswordField,
        disabled: !allowedUserInputEdit,
        hideShowPasswordIcon: !allowedUserInputEdit
      }
    ]
  };

  const validationSchema: any = {
    marketo: {},
    salesforce: {}
  };

  for (const platform in fields) {
    fields[platform].forEach((field: any) => {
      validationSchema[platform][field.id] = field.validationSchema;
    });
  }

  console.log("initialValues", initialValues);

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SECRETS.APICREDENTIALS"
          level1link="/app/home"
          level2name="SECRETS.APICREDENTIALS.SECRETS"
          instructions="SECRETS.APICREDENTIALS.INSTRUCTIONS"
        />
      </div>
      <div className="card px-3 mb-xl-3">
        {initialValues.marketo && initialValues.salesforce ? (
          <div className="mt-10 mb-10">
            <Container fluid>
              <div>
                <h2 className="page-title">{intl.formatMessage({ id: "SECRETS.APICREDENTIALS" })}</h2>
                <div className="py-3">
                  <h3 className="section-title">Marketo</h3>
                  <Form
                    className="py-2"
                    fields={fields.marketo}
                    initialValues={initialValues.marketo}
                    validationSchema={Yup.object().shape(validationSchema.marketo)}
                    handleSubmit={(values: any, { setSubmitting }: { setSubmitting: any }) =>
                      handleSubmit("marketo", values, { setSubmitting })
                    }
                  />
                </div>
                <div style={{ marginTop: 50 }}>
                  <h3 className="section-title">Salesforce</h3>
                  {isSfdcEnabled ? (
                    <Form
                      fields={fields.salesforce}
                      initialValues={initialValues.salesforce}
                      validationSchema={Yup.object().shape(validationSchema.salesforce)}
                      handleSubmit={(values: any, { setSubmitting }: { setSubmitting: any }) =>
                        handleSubmit("salesforce", values, { setSubmitting })
                      }
                    />
                  ) : (
                    <div>
                      <div className="py-3">
                        {intl.formatMessage({
                          id: "SECRETS.SFDC.ENABLE_INSTRUCTIONS"
                        })}
                      </div>
                      <Button>
                        <NavLink style={{ color: "white" }} href="/app/configs/salesforce_settings">
                          {intl.formatMessage({
                            id: "SECRETS.SFDC.ENABLE_LINK_TEXT"
                          })}
                        </NavLink>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <InsSpinner />
        )}
      </div>
    </>
  );
};

export default Secrets;
