const FieldTypeConvert = (sfdcType: string) => {
  // Field type conversion -> SFDC field list types to Query Builder types (default is "text")
  let fieldType = "";
  switch (sfdcType) {
    case "string" || "address" || "email" || "textarea" || "url" || "reference" || "id" || "phone":
      fieldType = "text";
      break;
    case "picklist":
      fieldType = "select";
      break;
    case "boolean":
      fieldType = "boolean";
      break;
    case "double" || "currency" || "int":
      fieldType = "number";
      break;
    case "date":
      fieldType = "date";
      break;
    case "datetime":
      fieldType = "datetime";
      break;
    default:
      fieldType = "text";
  }
  return fieldType;
};

export default FieldTypeConvert;
