import { FC } from "react";
import { useIntl } from "react-intl";
import { ID, RoleUser } from "../../core/_models";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";

type Props = {
  users?: Array<RoleUser>;
  id: ID;
};

const TableRoleUsersCell: FC<Props> = ({ users, id }) => {
  const intl = useIntl();

  const { setRoleIdForUpdate } = useRolesView();

  const numberOfUsers = users?.length || 0;

  const openModal = () => {
    setRoleIdForUpdate((prevState) => {
      return { id: id, type: "LIST_USERS" };
    });
  };

  return (
    <div className="w-100 text-start">
      {numberOfUsers ? (
        <div id="role-cell" className="text-body text-start">
          <button 
            onClick={openModal} 
            className="btn-link" 
            aria-label={`${numberOfUsers} ${intl.formatMessage({ id: "ROLES.TABLE.CELL.USERS" })}`}
          >
            {numberOfUsers} {intl.formatMessage({ id: "ROLES.TABLE.CELL.USERS" })}
          </button>
        </div>
      ) : (
        <div id="role-cell-no-role" className="badge badge-light-danger text-end px-4 py-2">
          {intl.formatMessage({ id: "ROLES.TABLE.CELL.USERS.NOUSER" })}
        </div>
      )}
    </div>
  );
};

export { TableRoleUsersCell };
