import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import { useInsJSFilesRequest } from "../core/InsJSFilesProvider";
import { useIntl } from "react-intl";
import { useRBAC } from "../../../roles-permissions/core/RBACProvide";

const ActionsUpdate = (props: any) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useInsJSFilesRequest();
  const intl = useIntl();
  const { allowedUserButtonClick } = useRBAC();

  const openEditModal = () => {
    console.log("Update JS file with ID: ", props.jsFileId);
    setItemIdForUpdate((prev) => props.jsFileId);
    console.log("Item Id for Update: ", itemIdForUpdate);
  };

  return (
    <div className="menu-item px-3">
      <button
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        onClick={openEditModal}
        data-tooltip-id={`updateInsJSFile-${props.jsFileId}`}
        aria-label={intl.formatMessage({ id: "INSENTRICJS.EDIT" })}
        disabled={!allowedUserButtonClick}
      >
        <KTIcon iconName="pencil" className="fs-2 m-0 actionIcon" />
      </button>
      <Tooltip
        id={`updateInsJSFile-${props.jsFileId}`}
        key={`updateInsJSFile-${props.jsFileId}`}
        content={intl.formatMessage({ id: "INSENTRICJS.EDIT" })}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export default ActionsUpdate;
