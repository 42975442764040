import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { ID, InsJSFilesContextProps, initialInsJSFilesRequest } from "./_models";

const InsJSFilesContext = createContext<InsJSFilesContextProps>(initialInsJSFilesRequest);

const InsJSFilesProvider = ({ children }: { children: any }) => {
  const [trackerFiles, setTrackerFiles] = useState([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialInsJSFilesRequest.itemIdForUpdate);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [isInitialAndConversion, setIsInitialAndConversion] = useState<boolean>();

  useEffect(() => {
    axios
      .get("/insentric-js")
      .then((response: any) => {
        const formatedResponse = response.data.map((file: any) => {
          return {
            ...file,
            fileLink: `https://cdn.insentric.net/${file.id}.js`,
            embedCode: `<script src="https://cdn.insentric.net/${file.id}.js"></script>`
          };
        });
        setTrackerFiles(formatedResponse);
        setIsFetching(false);
        setRefetch(false);
      })
      .catch((error: any) => {
        console.error("Error : ", error);
        setIsFetching(false);
        setRefetch(false);
      });
  }, [refetch]);

  return (
    <InsJSFilesContext.Provider
      value={{
        trackerFiles,
        isFetching,
        itemIdForUpdate,
        setItemIdForUpdate,
        setRefetch,
        isInitialAndConversion,
        setIsInitialAndConversion
      }}
    >
      {children}
    </InsJSFilesContext.Provider>
  );
};

const useInsJSFilesRequest = () => useContext(InsJSFilesContext);

export { InsJSFilesProvider, useInsJSFilesRequest };
