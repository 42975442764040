import { Form as BootstrapForm } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

type TextFieldProps = {
  id: string;
  label: string;
  isInvalid?: boolean;
  description?: string;
  optional?: boolean;
  disabled?: boolean;
  classes?: string;
  placeholder?: string;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Text type field component that uses Formik's Field component to render a text input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} label - The label for the field
 * @prop {boolean} [isInvalid=false] - Whether the field is invalid or not
 * @prop {string} [description] - The description for the field
 * @prop {boolean} [optional=false] - Whether the field is optional or not
 * @prop {boolean} [disabled=false] - Whether the field is disabled or not
 * @prop {string} [classes] - Any additional classes to apply to the field
 * @prop {string} [placeholder] - The placeholder for the field
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

const TextField = ({
  id,
  label,
  isInvalid = false,
  description,
  optional = false,
  disabled = false,
  classes,
  placeholder,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: TextFieldProps) => {
  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>{`${label}${optional ? " (optional)" : ""}`}</BootstrapForm.Label>
      )}
      <Field
        type="text"
        id={id}
        name={id}
        as={BootstrapForm.Control}
        isInvalid={isInvalid}
        className={classes ? `form-input ${classes}` : "form-input"}
        disabled={disabled}
        placeholder={placeholder}
        aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
        aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
        {...props}
      />
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default TextField;
