type ForbiddenWordsType = string[];

export const forbiddenWords = [
    // First Batch
    "P@ssw0rd", "Pass@123", "Aa@123456", "Admin@123", "Demo@123", "Abcd@1234", "Password@123", 
    "Abcd@123", "Welcome@123", "Pass@1234", "India@123", "Kumar@123", "123456aA@", "Qwerty123", 
    "Test@123", "123456a@", "Abc@1234", "Password@123", "Welcome@123", "Admin@123", "Qwerty123!", 
    "Passw0rd!", "Admin@2024", "Hello@123", "Summer2024!", "Winter2024!", "Secure@123", 
    "Change@2024",

    // Second Batch
    "N0=Acc3ss", "ka_dJKHJsy6", "Abc123456!", "!Turbine1", "fxzZ75$yer", "S9QxA9Yn9Cc=", 
    "1qaz!QAZ", "Temp2014!", "Comply1!", "Xz1!8xQv", "1a2B@c3D", "Test#123", "Passw0rd@", 
    "Admin2021!", "Super@123", "Hello#123", "Summer@2023", "Winter#2023", "Secure@2024", 
    "Change#2025",

    // Third Batch
    "p4$$w0rd", "p4$$w0rD", "p4$$w0Rd", "p4$$w0RD", "p4$$W0rd", "p4$$W0rD", "p4$$W0Rd", 
    "p4$$W0RD", "P4$$w0rd", "P4$$w0rD", "P4$$w0Rd", "P4$$w0RD", "P4$$W0rd", "P4$$W0rD", 
    "P4$$W0Rd", "P4$$W0RD", "p4$$word", "p4$$worD", "p4$$woRd", "p4$$woRD", "p4$$wOrd", 
    "p4$$wOrD", "p4$$wORd", "p4$$wORD", "p4$$Word", "p4$$WorD", "p4$$WoRd", "p4$$WoRD", 
    "p4$$WOrd", "p4$$WOrD", "p4$$WORd", "p4$$WORD", "P4$$word", "P4$$worD", "P4$$woRd", 
    "P4$$woRD", "P4$$wOrd", "P4$$wOrD", "P4$$wORd", "P4$$wORD", "P4$$Word", "P4$$WorD", 
    "P4$$WoRd", "P4$$WoRD", "P4$$WOrd", "P4$$WOrD", "P4$$WORd", "P4$$WORD", "p@55w0rd", 
    "p@55w0rD", "p@55w0Rd", "p@55w0RD", "p@55W0rd", "p@55W0rD", "p@55W0Rd", "p@55W0RD", 
    "P@55w0rd", "P@55w0rD", "P@55w0Rd", "P@55w0RD", "P@55W0rd", "P@55W0rD", "P@55W0Rd", 
    "P@55W0RD", "p@55word", "p@55worD", "p@55woRd", "p@55woRD", "p@55wOrd", "p@55wOrD", 
    "p@55wORd", "p@55wORD", "p@55Word", "p@55WorD", "p@55WoRd", "p@55WoRD", "p@55WOrd", 
    "p@55WOrD", "p@55WORd", "p@55WORD", "P@55word", "P@55worD", "P@55woRd", "P@55woRD", 
    "P@55wOrd", "P@55wOrD", "P@55wORd", "P@55wORD", "P@55Word", "P@55WorD", "P@55WoRd", 
    "P@55WoRD", "P@55WOrd", "P@55WOrD", "P@55WORd", "P@55WORD",

    // Fourth Batch
    "P@$$W0RD", "p@$$word", "p@$$worD", "p@$$woRd", "p@$$woRD", "p@$$wOrd", "p@$$wOrD", 
    "p@$$wORd", "p@$$wORD", "p@$$Word", "p@$$WorD", "p@$$WoRd", "p@$$WoRD", "p@$$WOrd", 
    "p@$$WOrD", "p@$$WORd", "p@$$WORD", "P@$$word", "P@$$worD", "P@$$woRd", "P@$$woRD", 
    "P@$$wOrd", "P@$$wOrD", "P@$$wORd", "P@$$wORD", "P@$$Word", "P@$$WorD", "P@$$WoRd", 
    "P@$$WoRD", "P@$$WOrd", "P@$$WOrD", "P@$$WORd", "P@$$WORD", "p455w0rd", "p455w0rD", 
    "p455w0Rd", "p455w0RD", "p455W0rd", "p455W0rD", "p455W0Rd", "p455W0RD", "P455w0rd", 
    "P455w0rD", "P455w0Rd", "P455w0RD", "P455W0rd", "P455W0rD", "P455W0Rd", "P455W0RD", 
    "p455word", "p455worD", "p455woRd", "p455woRD", "p455wOrd", "p455wOrD", "p455wORd", 
    "p455wORD", "p455Word", "p455WorD", "p455WoRd", "p455WoRD", "p455WOrd", "p455WOrD", 
    "p455WORd", "p455WORD", "P455word", "P455worD", "P455woRd", "P455woRD", "P455wOrd", 
    "P455wOrD", "P455wORd", "P455wORD", "P455Word", "P455WorD", "P455WoRd", "P455WoRD", 
    "P455WOrd", "P455WOrD", "P455WORd", "P455WORD", "p4ssw0rd", "p4ssw0rD", "p4ssw0Rd", 
    "p4ssw0RD", "p4ssW0rd", "p4ssW0rD", "p4ssW0Rd", "p4ssW0RD", "p4sSw0rd", "p4sSw0rD", 
    "p4sSw0Rd", "p4sSw0RD", "p4sSW0rd", "p4sSW0rD", "p4sSW0Rd", "p4sSW0RD", "p4Ssw0rd", 
    "p4Ssw0rD", "p4Ssw0Rd", "p4Ssw0RD", "p4SsW0rd", "p4SsW0rD", "p4SsW0Rd", "p4SsW0RD", 
    "p4SSw0rd", "p4SSw0rD", "p4SSw0Rd", "p4SSw0RD", "p4SSW0rd", "p4SSW0rD", "p4SSW0Rd", 
    "p4SSW0RD", "P4ssw0rd", "P4ssw0rD", "P4ssw0Rd", "P4ssw0RD", "P4ssW0rd", "P4ssW0rD", 
    "P4ssW0Rd", "P4ssW0RD", "P4sSw0rd", "P4sSw0rD", "P4sSw0Rd", "P4sSw0RD", "P4sSW0rd", 
    "P4sSW0rD", "P4sSW0Rd", "P4sSW0RD", "P4Ssw0rd", "P4Ssw0rD", "P4Ssw0Rd", "P4Ssw0RD", 
    "P4SsW0rd", "P4SsW0rD", "P4SsW0Rd", "P4SsW0RD", "P4SSw0rd", "P4SSw0rD", "P4SSw0Rd", 
    "P4SSw0RD", "P4SSW0rd", "P4SSW0rD", "P4SSW0Rd", "P4SSW0RD", "p4ssword", "p4ssworD", 
    "p4sswoRd", "p4sswoRD", "p4sswOrd", "p4sswOrD", "p4sswORd", "p4sswORD", "p4ssWord", 
    "p4ssWorD", "p4ssWoRd", "p4ssWoRD", "p4ssWOrd", "p4ssWOrD", "p4ssWORd", "p4ssWORD", 
    "p4sSword", "p4sSworD", "p4sSwoRd", "p4sSwoRD", "p4sSwOrd", "p4sSwOrD", "p4sSwORd", 
    "p4sSwORD", "p4sSWord", "p4sSWorD", "p4sSWoRd", "p4sSWoRD", "p4sSWOrd", "p4sSWOrD", 
    "p4sSWORd", "p4sSWORD", "p4Ssword", "p4SsworD", "p4SswoRd", "p4SswoRD", "p4SswOrd", 
    "p4SswOrD", "p4SswORd", "p4SswORD", "p4SsWord", "p4SsWorD", "p4SsWoRd", "p4SsWoRD", 
    "p4SsWOrd", "p4SsWOrD", "p4SsWORd", "p4SsWORD", "p4SSword", "p4SSworD", "p4SSwoRd", 
    "p4SSwoRD", "p4SSwOrd", "p4SSwOrD", "p4SSwORd", "p4SSwORD", "p4SSWord", "p4SSWorD", 
    "p4SSWoRd", "p4SSWoRD", "p4SSWOrd", "p4SSWOrD", "p4SSWORd", "p4SSWORD", "P4ssword", 
    "P4ssworD", "P4sswoRd", "P4sswoRD", "P4sswOrd", "P4sswOrD", "P4sswORd", "P4sswORD", 
    "P4ssWord", "P4ssWorD", "P4ssWoRd", "P4ssWoRD", "P4ssWOrd", "P4ssWOrD", "P4ssWORd", 
    "P4ssWORD", "P4sSword", "P4sSworD", "P4sSwoRd", "P4sSwoRD", "P4sSwOrd", "P4sSwOrD", 
    "P4sSwORd", "P4sSwORD", "P4sSWord", "P4sSWorD", "P4sSWoRd", "P4sSWoRD", "P4sSWOrd", 
    "P4sSWOrD", "P4sSWORd", "P4sSWORD", "P4Ssword", "P4SsworD", "P4SswoRd", "P4SswoRD", 
    "P4SswOrd", "P4SswOrD", "P4SswORd", "P4SswORD", "P4SsWord", "P4SsWorD", "P4SsWoRd", 
    "P4SsWoRD", "P4SsWOrd", "P4SsWOrD", "P4SsWORd", "P4SsWORD", "P4SSword", "P4SSworD", 
    "P4SSwoRd", "P4SSwoRD", "P4SSwOrd", "P4SSwOrD", "P4SSwORd", "P4SSwORD", "P4SSWord", 
    "P4SSWorD"
] as ForbiddenWordsType;
