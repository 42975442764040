import { Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage, useField } from "formik";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { useState } from "react";
import { handleRefreshClick } from "../helper/helpers";
import styles from "../style/SingleSelectDropdown.module.css";

type SingleSelectDropdownProps = {
  id: string;
  label?: string;
  description?: string;
  options?: { value: string; label: string }[];
  selectedOption?: string;
  showRefreshButton?: boolean;
  refreshType?: string;
  classes?: string;
  isDisabled?: boolean;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Single select dropdown field component that uses the "react-select" component to render a single select dropdown input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} [label=""] - The label for the field
 * @prop {string} [description=""] - The description for the field
 * @prop {{ value: string; label: string }[]} [options=[]] - The options for the field
 * @prop {string} [selectedOption=""] - The selected option for the field
 * @prop {boolean} [showRefreshButton=false] - Whether to show the refresh button or not
 * @prop {string} [refreshType] - The type of refresh to perform
 * @prop {string} [classes=""] - Any additional classes to apply to the field
 * @prop {boolean} [isDisabled=false] - Whether the field is disabled or not
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const SingleSelectDropdown = ({
  id,
  label = "",
  description = "",
  options = [],
  selectedOption = "",
  showRefreshButton = false,
  refreshType,
  classes = "",
  isDisabled = false,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: SingleSelectDropdownProps) => {
  const [, meta, helpers] = useField(id);
  const intl = useIntl();
  const { value } = meta;
  const { setValue } = helpers;
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleChange = (option: { value: string; label: string } | null): void => {
    setValue(option ? option.value : "");
  };

  const placeholder = intl.formatMessage({ id: "FORM.SELECT" });

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label && <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>}
      <div className="d-flex align-items-center flex-help">
        <Select
          id={id}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
          options={options}
          value={options.find((option) => option.value === value)}
          onChange={handleChange}
          className={classes}
          placeholder={placeholder}
          isDisabled={isDisabled}
          {...props}
        />
        {showRefreshButton && (
          <FontAwesomeIcon
            icon={faSync}
            onClick={() => handleRefreshClick(refreshType || "", setIsRefreshing, intl)}
            className={isRefreshing ? `${styles.refreshBtnIcon} spin` : `${styles.refreshBtnIcon}`}
          />
        )}
      </div>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default SingleSelectDropdown;
