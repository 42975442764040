import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";

const RolesModalHeader = () => {
  const intl = useIntl();
  const { roleIdForUpdate, setRoleIdForUpdate } = useRolesView();

  return (
    <div className="modal-header justify-content-between">
      <h2 className="fw-bolder">
        {roleIdForUpdate.type === "ADD_NEW_ROLE" && intl.formatMessage({ id: "ROLES.MODAL.ADDNEWROLE.HEADER" })}
        {roleIdForUpdate.type === "EDIT_ROLE" && intl.formatMessage({ id: "ROLES.MODAL.UPDATEROLE.HEADER" })}
        {roleIdForUpdate.type === "LIST_USERS" && intl.formatMessage({ id: "ROLES.MODAL.LISTUSERS.HEADER" })}

      </h2>
      <button
        type="button"
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => setRoleIdForUpdate((prevState) => ({ id: undefined, type: null }))} // Close the modal
        style={{ cursor: "pointer" }}
        aria-label="Close"
      >
        <KTIcon iconName="cross" className="fs-1" />
      </button>
    </div>
  );
};

export { RolesModalHeader };
