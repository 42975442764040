import { useIntl } from "react-intl";
import NewRoleBtn from "./NewRoleBtn";

const RolesHeader = () => {
  const intl = useIntl();
  return (
    <div className="card-header border-0 pt-6">
      <h2 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-1 mb-1">{intl.formatMessage({ id: "ROLES.HEADERLABEL.ROLES" })}</span>
      </h2>
      <div className="card-toolbar">
        <NewRoleBtn />
      </div>
    </div>
  );
};

export default RolesHeader;
