import { Navigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { VerificationProvider } from "./core/VerificationProvider";
import UserVerification from "./UserVerification";

const UserVerificationWrapper = () => {
  const { currentUser } = useAuth();
  console.log("CURRENT USER : ", currentUser);

  const isCurrentUser = currentUser ? true : false;

  return (
    <>
      {!isCurrentUser ? (
        <VerificationProvider>
          <UserVerification />
        </VerificationProvider>
      ) : (
        <Navigate to="/app/home" />
      )}
    </>
  );
};

export default UserVerificationWrapper;
