import { object, number, array, string } from "yup";

export const validateStep1 = object({
  maindomain: string()
    .required("SETTINGS.ACCOUNT.INSENTRICJS.MAINDOMAINERROR")
    .lowercase("SETTINGS.ACCOUNT.INSENTRICJS.LOWERCASE")
    .matches(
      /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
      "SETTINGS.ACCOUNT.INSENTRICJS.VALIDDOMAIN"
    ),
  cookie_months: number()
    .required("SETTINGS.ACCOUNT.INSENTRICJS.COOKIEMONTHSERROR")
    .positive()
    .integer(),
  validdomains: array()
    .of(
      object().shape({
        label: string(),
        value: string()
      })
    )
    .min(1, "SETTINGS.ACCOUNT.INSENTRICJS.VALIDDOMAINSERROR")
    .required("SETTINGS.ACCOUNT.INSENTRICJS.VALIDDOMAINSERROR")
});

export const validateStep2 = object({
  source: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  medium: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  campaign: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  term: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  content: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS")
});

export const validateStep3 = object({
  pmfcSource: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  pmfcMedium: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  pmfcCampaign: string().required(
    "SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"
  ),
  pmfcTerm: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  pmfcContent: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS")
});

export const validateStep4 = object({
  tempSource: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  tempMedium: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  tempCampaign: string().required(
    "SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"
  ),
  tempTerm: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS"),
  tempContent: string().required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS")
});

export const validateStep5 = object().shape({
  referrals: array()
    .of(
      object().shape({
        host: string()
          .required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS")
          .lowercase("SETTINGS.ACCOUNT.INSENTRICJS.LOWERCASE")
          .matches(
            /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
            "SETTINGS.ACCOUNT.INSENTRICJS.VALIDDOMAIN"
          ),
        medium: string()
          .required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS")
          .lowercase("SETTINGS.ACCOUNT.INSENTRICJS.LOWERCASE"),
        display: string()
          .required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS")
          .lowercase("SETTINGS.ACCOUNT.INSENTRICJS.LOWERCASE")
      })
    )
    .min(1, "SETTINGS.ACCOUNT.INSENTRICJS.MINONEDOMAIN")
    .required("SETTINGS.ACCOUNT.INSENTRICJS.REQUIREDFIELDS")
    .strict()
});