import { Form as BootstrapForm } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { ThemeModeComponent } from "../../../../assets/ts/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { handleRefreshClick } from "../helper/helpers";
import Creatable from "react-select/creatable";
import styles from "../style/MultiSelectDropdownWithInput.module.css";

type MultiSelectDropdownWithInputProps = {
  id: string;
  label?: string;
  description?: string;
  options?: { value: string; label: string }[];
  showRefreshButton?: boolean;
  refreshType?: string | null;
  classes?: string;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Single select dropdown with Input field component that uses the Creatable "react-select" component to render a single select dropdown input field with an input option.
 *
 * @prop {string} id - The id of the field
 * @prop {string} [label=""] - The label for the field
 * @prop {string} [description=""] - The description for the field
 * @prop {{ value: string; label: string }[]} [options=[]] - The options for the field
 * @prop {boolean} [showRefreshButton=false] - Whether to show the refresh button or not
 * @prop {string} [refreshType] - The type of refresh to perform
 * @prop {string} [classes=""] - Any additional classes to apply to the field
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const MultiSelectDropdownWithInput = ({
  id,
  label = "",
  description = "",
  options = [],
  showRefreshButton = false,
  refreshType = null,
  classes = "",
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: MultiSelectDropdownWithInputProps) => {
  const [, meta, helpers] = useField(id);
  const intl = useIntl();
  const { value } = meta;
  const { setValue } = helpers;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [themeMode, setThemeMode] = useState(ThemeModeComponent.getMode());

  const handleChange = (selectedOptions: any) => {
    setValue(selectedOptions);
  };

  const handleCreateOption = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };
    setValue([...value, newOption]);
  };

  useEffect(() => {
    setThemeMode((prevState) => ThemeModeComponent.getMode());
  }, [ThemeModeComponent.getMode()]);

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>}
      <div className="d-flex align-items-center flex-help">
        <Creatable
          id={id}
          name={id}
          isMulti
          value={value}
          onChange={handleChange}
          onCreateOption={handleCreateOption}
          options={options}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
          noOptionsMessage={() => "Type to add new option"}
          className={classes}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : "")}
          {...props}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              neutral80: themeMode === "dark" ? "#ffffff" : "#333333"
            }
          })}
        />
        {showRefreshButton && refreshType && (
          <FontAwesomeIcon
            icon={faSync}
            onClick={() => handleRefreshClick(refreshType, setIsRefreshing, intl)}
            className={isRefreshing ? `${styles.refreshBtnIcon} spin` : `${styles.refreshBtnIcon}`}
          />
        )}
      </div>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default MultiSelectDropdownWithInput;
