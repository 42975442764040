import { TextField } from "../../../../../layout/components/form";
import { useIntl } from "react-intl";

const FormFieldParametersToken = (props: any) => {
  const intl = useIntl();
  return (
    <div className="m-3">
      <span className="fs-5 fw-bold" id="params-token-label">
        {intl.formatMessage({
          id: "SETTINGS.MKTO.QSAPPEND.PARAMETERSTOKEN"
        })}
      </span>
      <div className="text-muted mb-3" id="params-token-description">
        {intl.formatMessage({
          id: "SETTINGS.MKTO.QSAPPEND.PARAMETERSTOKEN.DESC"
        })}
      </div>
      <TextField
        id="mkto_qs_appender_parameters_token"
        label=""
        hideLabel={true}
        description=""
        placeholder=""
        hideDescription={true}
        ariaLabelledBy="params-token-label"
        ariaDescribedBy="params-token-description"
        optional={false}
        classes={
          props.errors.mkto_qs_appender_parameters_token &&
          props.touched.mkto_qs_appender_parameters_token
            ? "border-danger"
            : ""
        }
        isInvalid={false}
      />
      {!!props.errors.mkto_qs_appender_parameters_token &&
      props.touched.mkto_qs_appender_parameters_token ? (
        <p className="text-danger">
          {intl.formatMessage({
            id: props.errors.mkto_qs_appender_parameters_token
          })}
        </p>
      ) : null}
    </div>
  );
};

export default FormFieldParametersToken;
