import { useState } from "react";
import { useRolesView } from "../../../core/RolesAndPermissionsProvider";
import { useIntl } from "react-intl";

const rolesList = ["Admin", "User", "Demo User"];

const ModalBodyUsers = () => {
  const intl = useIntl();
  const { roles, roleIdForUpdate } = useRolesView();

  const [isSaving, setIsSaving] = useState(false);

  const role = roles.find((role) => role.id === roleIdForUpdate.id);

  const handleSaveChanges = () => {
    setIsSaving(true);

    setTimeout(() => {
      setIsSaving(false);
    }, 2000);
  };

  return (
    <div className="row">
      {role?.users.map((user) => (
        <>
          <div key={user.id} className="col-12 col-md-7 p-2 m-2 d-flex align-items-center">
            {user.name}
          </div>
          <div className="col-12 col-md-4 p-2 m-2 d-flex align-items-center ">
            <select className="form-select" aria-label="Default select example">
              {rolesList.map((roleItem) => (
                <option key={roleItem} selected={role.roleName === roleItem}>
                  {roleItem}
                </option>
              ))}
            </select>
          </div>
        </>
      ))}
      <div className="text-end mt-6">
        <button
          type="button"
          onClick={handleSaveChanges}
          className={isSaving ? "btn btn-primary me-2 disabled" : "btn btn-primary me-2"}
        >
          {!isSaving && <span className="indicator-label">{intl.formatMessage({ id: "BUTTON.SAVE" })}</span>}
          {isSaving && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "FORM.PLEASEWAIT" })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default ModalBodyUsers;
