/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useAuth } from "../../../../pages/auth";
import { Languages } from "./Languages";
import { useIntl } from "react-intl";
import { ThemeModeMenu } from "./ThemeModeMenu";
import { UserAvatar } from "./UserAvatar";
import { KTIcon } from "../../../../helpers";

const HeaderUserMenu: FC = () => {
  const intl = useIntl();
  const { currentUser, logout } = useAuth();

  const firstName = currentUser ? currentUser.first_name || "Unknown" : "Unknown";
  const lastName = currentUser ? currentUser.last_name || "User" : "User";

  // Check if any role has the name "Admin"
  const isAdmin =
    currentUser &&
    Array.isArray(currentUser.roles) &&
    currentUser.roles.some((r: { name: string }) => r.name === "Admin");

  return (
    <>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
        role="menu"
      >
        <div role="menuitem" className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="overflow-hidden rounded me-3">
              <UserAvatar />
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {firstName} {lastName}
                {isAdmin && <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Admin</span>}
              </div>
              <a href="#" className="fw-bold text-muted text-hover-primary fs-7 text-break">
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className="separator my-2"></div>

        <div role="menuitem" className="menu-item px-5">
          <a href="/app/profile" className="menu-link px-5 gap-3">
            <KTIcon iconName="user" className="fs-2"></KTIcon>
            <span className="menu-title">{intl.formatMessage({ id: "USER.MYPROFILE" })}</span>
          </a>
        </div>

        <div
          role="menuitem"
          className="menu-item px-5"
          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
          data-kt-menu-placement="left-start"
          data-kt-menu-offset="-15px, 0"
        >
          <a href="#" className="menu-link px-5">
            <span className="menu-title position-relative gap-3">
              <KTIcon iconName="night-day" className="fs-2 theme-light-show"></KTIcon>
              <KTIcon iconName="moon" className="fs-2 theme-dark-show"></KTIcon>
              {intl.formatMessage({ id: "MODE.MODE" })}
            </span>
          </a>

          <ThemeModeMenu />
        </div>

        <Languages />

        <div className="separator my-2"></div>

        <div className="menu-item px-5">
          <button onClick={logout} className="menu-link px-5 d-flex gap-3" role="menuitem">
            <KTIcon iconName="exit-right" className="fs-2"></KTIcon>
            {intl.formatMessage({ id: "AUTH.LOGIN.LOGOUT" })}
          </button>
        </div>
      </div>
    </>
  );
};

export { HeaderUserMenu };
