import { ReactNode } from "react";

export type WithChildren = {
  children?: ReactNode;
};

export type verificationContextProps = {
  mode: string;
  actionCode: string;
  continueUrl: string;
  lang: string;
  email: string;
  operation: string;
  multiFactorInfo: string;
  previousEmail: string;
  isActionCodeVerified: boolean;
  isActionCodeExpired?: boolean;
  isFetching?: boolean;
};

export const initialVerificationContext: verificationContextProps = {
  mode: "",
  actionCode: "",
  continueUrl: "",
  lang: "",
  email: "",
  operation: "",
  multiFactorInfo: "",
  previousEmail: "",
  isActionCodeVerified: false,
  isActionCodeExpired: false,
  isFetching: true,
};
