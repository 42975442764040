/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import InsentricJsConfigurator from "./insentric-js/InsentricJsConfigurator";
import AccountSettings from "./account-settings/AccountSettings";

const AccountSettingsWrapper: FC = () => {
  return (
    <>
      <div className="row gy-5 gx-xl-8">
        <div className="col-12">
          <Routes>
            <Route
              path="/insentric-js-configurator"
              element={<InsentricJsConfigurator />}
            />
            <Route
              path="/account-settings"
              element={<AccountSettings />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

export { AccountSettingsWrapper };
