import { useIntl } from "react-intl";
import clsx from "clsx";

const ObjectSetupRenderFields = (props: any) => {
  const intl = useIntl();

  return (
    <>
      {props.loading && (
        <span
          className="indicator-progress d-flex justify-content-center align-items-center"
          style={{ maxHeight: 600 }}
        >
          <span className="spinner-border spinner-border-lg align-middle ms-2"></span>
          <span style={{ fontSize: 16, paddingLeft: 10 }}>
            {intl.formatMessage({
              id: "SETTINGS.OBJECTFIELDS.FETCHINGFIELDS"
            })}
          </span>
        </span>
      )}
      <div
        className="card-body pt-2 columns-break-lg-3 columns-break-md-2 columns-break-sm-1 overflow-auto"
        style={{ maxHeight: 600 }}
      >
        {props.renderFieldsList.length > 0 &&
          props.renderFieldsList.map((field: any, index: any) => (
            <div
              className="d-flex align-items-center mb-8"
              key={`${field.name}_${index}`}
            >
              <div
                className="form-check form-check-custom form-check-solid mx-5"
                style={{ top: "1.2rem !important", scale: "1.7" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={field.name}
                  name={field.name}
                  disabled={field.tempIsDisabled}
                  checked={field.tempIsChecked}
                  onChange={(e) => props.handleChange(e)}
                />
              </div>
              <div className="flex-grow-1 text-muted">
                <label htmlFor={field.name}>
                  <span
                    className={clsx(
                      "text-gray-800 fw-bold fs-6d",
                      {
                        "text-disabled": field.insentricStatus === "unavailable"
                      },
                      {
                        "text-muted": field.isUpdateable
                      }
                    )}
                  >
                    {field.displayName}
                  </span>
                  <span
                    className={clsx(
                      "fw-semibold d-block text-break",
                      {
                        "text-disabled": field.insentricStatus === "unavailable"
                      },
                      {
                        "text-muted": field.isUpdateable
                      }
                    )}
                  >
                    {intl.formatMessage({
                      id: "SETTINGS.OBJECTFIELDS.APINAME"
                    })}{" "}
                    {field.name}
                  </span>
                </label>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default ObjectSetupRenderFields;
