import { object, string } from "yup";
import { forbiddenWords } from "../../user-verification/core/_forbiddenWords";

const specialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const passwordValidationSchema = object({
  password: string()
    .required("USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.REQUIRED")
    // check minimum characters
    .min(8, "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.MINLENGTH")
    // different error messages for different requirements
    .matches(/[0-9]/, "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.DIGIT")
    .matches(/[a-z]/, "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.LOWERCASE")
    .matches(/[A-Z]/, "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.UPPERCASE")
    .matches(specialChar, "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.SPECIALCHAR")
    .test({
      message: () => "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.NOSPACE",
      test: (value) => !/\s/.test(value)
    })
    .test({
      message: () => "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.FORBIDDENWORDS",
      test: (value) => {
        const forbidden = forbiddenWords.some(
          (word) => value.toLocaleLowerCase() === word.toLocaleLowerCase()
        );
        return !forbidden;
      }
    }),
  confirmPassword: string().test({
    message: "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.DONOTMATCH",
    test: function (value) {
      return value === this.parent.password;
    }
  }),
  currentPassword: string().required("USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.CURRENTREQUIRED")
});
