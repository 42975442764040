import { ColumnInstance, Row, useTable } from "react-table";
import { useMemo } from "react";
import { TableCustomHeaderColumn } from "./TableCustomHeaderColumn";
import { Role } from "../../core/_models";
import { rolesColumns } from "./_columns";
import { useIntl } from "react-intl";
import TableCustomRow from "./TableCustomRow";

import { useRolesView } from "../../core/RolesAndPermissionsProvider";

const RolesTable = () => {
  const intl = useIntl();
  const { roles } = useRolesView();

  const data = useMemo(() => roles, [roles]);
  const columns = useMemo(() => rolesColumns, []);
  
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <div className="table-responsive">
      <table
        id="roles"
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        {...getTableProps()}
      >
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            {headers.map((column: ColumnInstance<Role>) => (
              <TableCustomHeaderColumn key={column.id} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row<Role>, i) => {
              prepareRow(row);
              return <TableCustomRow row={row} key={`row-${i}-${row.id}`} />;
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  {intl.formatMessage({
                    id: "PAGINATION.NORECORDS"
                  })}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RolesTable;
