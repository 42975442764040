import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { MasterLayout } from '../../layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from '../../assets/ts/_utils'
import { WithChildren } from '../../helpers'
import { SFDCSetupWrapper } from '../salesforce-configuration/SFDCSetupWrapper'
import { MktoSetupWrapper } from '../marketo-configuration/MktoSetupWrapper'
import { RolesAndPermissionsWrapper } from '../roles-permissions/RolesPermission'
import DependenciesAcknowledgement from '../misc-pages/dependencies-acknowledgement/DependenciesAcknowledgement'
import { MktoConfigWrapper } from '../marketo-configuration/MktoConfigWrapper'
import { AccountSettingsWrapper } from '../account-settings/AccountSettingsWrapper'
import UserProfile from '../user-management/profile-page/UserProfile'
import { useRBAC } from '../roles-permissions/core/RBACProvide'
import { all } from 'axios'

const AppContentRoutes = () => (
  <div>
    {/* Other layout content */}
    <Outlet />  {/* Nested routes will render here */}
  </div>
);
const PrivateRoutes = () => {
  const { allowedUserViewPage } = useRBAC();

  const Home = lazy(() => import('../home/Home'));
  const MktoFetchProgram = lazy(() => import('../marketo-utilities/MktoFetchProgram'));
  const MktoFetchActivities = lazy(() => import('../marketo-utilities/MktoFetchActivities'));
  const SfdcSettings = lazy(() => import('../salesforce-configuration/components/SfdcSettings/SfdcSettings'));
  const UsersPage = lazy(() => import('../../pages/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Home after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/app/home' />} />
        {/* Pages */}
        <Route
          path='/app/'
          element={<AppContentRoutes />}
        >
          <Route path='home' element={<SuspensedView><Home /></SuspensedView>} />
          <Route path='fetch/marketo_programs' element={<SuspensedView><MktoFetchProgram /></SuspensedView>} />
          <Route path='fetch/marketo_activities' element={<SuspensedView><MktoFetchActivities /></SuspensedView>} />
          <Route path='configs/*' element={<SuspensedView><MktoConfigWrapper /></SuspensedView>} />
          <Route path='configs/salesforce_settings' element={<SuspensedView><SfdcSettings /></SuspensedView>} />
          <Route path='salesforce-config/*' element={<SuspensedView><SFDCSetupWrapper /></SuspensedView>} />
          <Route path='marketo-config/*' element={<SuspensedView><MktoSetupWrapper /></SuspensedView>} />
          <Route path='user-management/*' element={<SuspensedView><UsersPage /></SuspensedView>} />
          <Route path='account-settings/*' element={<SuspensedView><AccountSettingsWrapper /></SuspensedView>} />
          {allowedUserViewPage && <Route path='roles-and-permissions' element={<SuspensedView><RolesAndPermissionsWrapper /></SuspensedView>} />}
          
          <Route path='profile' element={<SuspensedView><UserProfile /></SuspensedView>} />

          <Route path='acknowledgements' element={<SuspensedView><DependenciesAcknowledgement /></SuspensedView>} />


        </Route>
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/app/home' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
