import ActionsDelete from "./ActionsDelete";
import ActionsUpdate from "./ActionsUpdate";

const Actions = (props:any) => {

  return (
    <div className="d-flex flex-row justify-content-center">

      <ActionsUpdate jsFileId={props.jsFileId}/>
      <ActionsDelete jsFileId={props.jsFileId}/>
    </div>
  );
};

export default Actions;
