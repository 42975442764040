import { useRolesView } from "../../core/RolesAndPermissionsProvider";

const PermissionsBodyWrapper = () => {
  const { roleIdForUpdate, roles } = useRolesView();

  const role = roles.find((role) => role.id === roleIdForUpdate.id);

  if (role) {
    return (
      <div className="d-flex flex-column align-items-center">
        <p>Permissions for {role.roleName}</p>
        <p>Permissions will be displayed here</p>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center">
      
      <p>No role selected...</p>
      <p>Please select a role to display permissions</p>
    </div>
  );

}

export default PermissionsBodyWrapper;