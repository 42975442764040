import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  role?: string;
};

const TableRoleNameCell: FC<Props> = ({ role }) => {
  const intl = useIntl();

  return (
    <div className="w-100 text-start">
      {role ? (
        <div id={`role-cell-${role}`} className="text-body text-start">
          {role}
        </div>
      ) : (
        <div
          id="role-cell-no-role"
          className="badge badge-light-danger text-end px-4 py-2"
        >
          {intl.formatMessage({ id: "ROLES.TABLE.CELL.ROLE.NOROLE" })}
        </div>
      )}
    </div>
  );
};

export { TableRoleNameCell };
