import PropTypes from "prop-types";
import { components } from "react-select";

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return (
    <components.Option {...newProps}>
      {children}
      <div className="tz-optionTime">{newProps.data.time}</div>
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseMove: PropTypes.func,
    onMouseOver: PropTypes.func
  })
};

export default Option;