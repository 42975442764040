import PropTypes from "prop-types";
import { KTIcon } from "../../../../helpers";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const TableRoleActionsCell = ({ id }: { id: string }) => {
  const intl = useIntl();

  const { setRoleIdForUpdate } = useRolesView();

  const handleEdit = () => {
    console.log("Edit role with id: ", id);
    setRoleIdForUpdate((prevState) => {
      return { id: id, type: "EDIT_ROLE" };
    });
  };

  const handleDelete = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "ROLES.DELETEROLE.TITLE" }),
      text: intl.formatMessage({ id: "ROLES.DELETEROLE.CONFIRMATION" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "ROLES.DELETEROLE.CONFIRMBTN" }),
      cancelButtonText: intl.formatMessage({ id: "BUTTON.CANCEL" })
    }).then(async (result) => {
      if (result.isConfirmed) {
        //
        // TODO: This is a placeholder for the actual delete logic
        //
        MySwal.fire(
          intl.formatMessage({ id: "ROLES.DELETEROLE.SUCCESS" }),
          intl.formatMessage({ id: "ROLES.DELETEROLE.SUCCESSMESSAGE" }),
          "success"
        );
      }
    });
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="menu-item px-3">
        <button
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          onClick={handleEdit}
          data-tooltip-id={`edit-role-tooltip-${id}`}
          data-tooltip-content={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.EDIT" })}
          data-tooltip-place="bottom"
          aria-label={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.EDIT" })}
          aria-describedby={`edit-role-tooltip-${id}`}
        >
          <KTIcon iconName="pencil" className="fs-2 m-0 actionIcon" />
        </button>
        <Tooltip id={`edit-role-tooltip-${id}`} style={{ zIndex: 100 }} />
      </div>

      <div className="menu-item px-3">
        <button
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          onClick={handleDelete}
          data-tooltip-id={`delete-role-tooltip-${id}`}
          data-tooltip-content={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.DELETE" })}
          data-tooltip-place="bottom"
          aria-label={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.DELETE" })}
          aria-describedby={`delete-role-tooltip-${id}`}
        >
          <KTIcon iconName="trash" className="fs-2 m-0 actionIcon" />
        </button>
        <Tooltip id={`delete-role-tooltip-${id}`} style={{ zIndex: 100 }} />
      </div>
    </div>
  );
};

TableRoleActionsCell.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string
};

export { TableRoleActionsCell };
