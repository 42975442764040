import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InsSpinner from '../spinner/InsSpinner';

interface Dependency {
  name: string;
  description: string;
  license: string;
  homepage?: string;
  license_url?: string;
}

interface DependenciesTableProps {
  className?: string;
}

const DependenciesTable: React.FC<DependenciesTableProps> = ({ className }) => {
  const [dependencies, setDependencies] = useState<Dependency[]>([]);
  const [loading, setLoading] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    axios.get('/ack')
      .then((response) => {
        if (response.data && typeof response.data === 'object') {
          // Convert the object to an array of dependencies
          const dependenciesArray = Object.keys(response.data).map((key) => ({
            name: key,
            ...response.data[key],
          }));
          setDependencies(dependenciesArray);
        } else {
          console.error('Unexpected response data format', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching dependencies:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-300px rounded-start'>
                  {intl.formatMessage({ id: 'SETTINGS.ACK.DEPENDENCY' })}
                </th>
                <th className='min-w-125px'>
                  {intl.formatMessage({ id: 'SETTINGS.ACK.DESCRIPTION' })}
                </th>
                <th className='min-w-125px'>
                  {intl.formatMessage({ id: 'SETTINGS.ACK.LICENSE' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3} className='text-center'>
                    <div className='d-flex mt-10 align-items-center justify-content-center'>
                      <InsSpinner />
                    </div>
                  </td>
                </tr>
              ) : (
                dependencies.sort((a, b) => a.name.localeCompare(b.name)).map((dependency) => (
                  <tr key={dependency.name}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          {/* Symbol Content */}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          {dependency.homepage ? (
                            <a href={dependency.homepage} target="_blank" rel="noreferrer" className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {dependency.name}
                            </a>
                          ) : (
                            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {dependency.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>{dependency.description}</td>
                    <td>
                      {dependency.license_url ? (
                        <a href={dependency.license_url} target="_blank" rel="noreferrer" className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {dependency.license}
                        </a>
                      ) : (
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {dependency.license}
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

DependenciesTable.propTypes = {
  className: PropTypes.string,
};

export default DependenciesTable;
