import { toAbsoluteUrl } from "../../helpers";

export const languages = [
    {
      lang: "en",
      name: "English",
      translationKey: "LANGUAGE.ENGLISH",
      flag: toAbsoluteUrl("/media/flags/united-states.svg")
    },
   /* {
      lang: "zh",
      name: "普通话",
      translationKey: "LANGUAGE.MANDARIN",
      flag: toAbsoluteUrl("/media/flags/china.svg")
    },
    {
      lang: "es",
      name: "Español",
      translationKey: "LANGUAGE.SPANISH",
      flag: toAbsoluteUrl("/media/flags/spain.svg")
    }, */
    {
      lang: "ja",
      name: "日本語",
      translationKey: "LANGUAGE.JAPANESE",
      flag: toAbsoluteUrl("/media/flags/japan.svg")
    }/*,
    {
      lang: "de",
      name: "Deutsch",
      translationKey: "LANGUAGE.GERMAN",
      flag: toAbsoluteUrl("/media/flags/germany.svg")
    },
    {
      lang: "fr",
      name: "Français",
      translationKey: "LANGUAGE.FRENCH",
      flag: toAbsoluteUrl("/media/flags/france.svg")
    }*/
  ];