/* eslint-disable react/no-danger */
/**
 * Note that we only use dangerously-set innerHTML for
 * the "Instructions" section of page headers, and that is
 * only so we can have multilingual support. Do not use
 * this design pattern elsewhere. - CG 2024-03-08
 */
import React, { useMemo } from 'react';
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

/**
 * Props for PageHeader component.
 */
interface PageHeaderProps {
  /** Optional URL for the first level link */
  level1link?: string;
  /** Optional name for the first level */
  level1name?: string;
  /** Optional URL for the second level link */
  level2link?: string;
  /** Optional name for the second level */
  level2name?: string;
  /** Optional URL for the third level link */
  level3link?: string;
  /** Optional name for the third level */
  level3name?: string;
  /** Optional URL for the fourth level link */
  level4link?: string;
  /** Optional name for the fourth level */
  level4name?: string;
  /** Optional instructions string */
  instructions?: string;  
}

/**
 * Custom hook for setting the document title.
 * @param title - The title to be set for the document.
 */
const useDocumentTitle = (title: string) => {
  React.useEffect(() => {
    document.title = title;
  }, [title]);
};

/**
 * Renders a PageHeader component with breadcrumb navigation and optional instructions.
 * 
 * @param props - The props for the component.
 * @returns The PageHeader component.
 */
const PageHeader: React.FC<PageHeaderProps> = ({
  level1link, level1name, level2link, level2name,
  level3link, level3name, level4link, level4name,
  instructions
}) => {
  const intl = useIntl();

  const formattedLevelNames = useMemo(() => {
    return [level1name, level2name, level3name, level4name]
      .filter(name => name)
      .map(name => intl.formatMessage({ id: name }));
  }, [level1name, level2name, level3name, level4name, intl]);

  const pageTitle = useMemo(() => {
    return formattedLevelNames.length > 0 
      ? `${formattedLevelNames.join(' » ')} - Insentric` 
      : 'Insentric';
  }, [formattedLevelNames]);

  useDocumentTitle(pageTitle);

  /**
   * Renders an individual breadcrumb item.
   * 
   * @param name - The name of the breadcrumb item.
   * @param link - The link associated with the breadcrumb item.
   * @param position - The position of the breadcrumb item in the breadcrumb list.
   * @returns A JSX element representing the breadcrumb item, or null if no name is provided.
   */
  const renderBreadcrumbItem = (name: string | undefined, link: string | undefined, position: number) => {
    if (!name) return null;

    const itemName = intl.formatMessage({ id: name });
    const isLastItem = position === formattedLevelNames.length;

    return (
      <li className={`breadcrumb-item text-muted`} 
          itemProp="itemListElement" 
          itemScope 
          itemType="https://schema.org/ListItem">
        {isLastItem ? (
          <span itemProp="name">{itemName}</span>
        ) : (
          <a href={link || '#'} itemScope itemType="https://schema.org/WebPage" itemProp="item">
            <span itemProp="name">{itemName}</span>
          </a>
        )}
        <meta itemProp="position" content={position.toString()} />
      </li>
    );
  };

  return (
    <>
      <h1 className="page-title">
        <ol className="breadcrumb text-muted fw-bold" itemScope itemType="https://schema.org/BreadcrumbList">
          {renderBreadcrumbItem(level1name, level1link, 1)}
          {renderBreadcrumbItem(level2name, level2link, 2)}
          {renderBreadcrumbItem(level3name, level3link, 3)}
          {renderBreadcrumbItem(level4name, level4link, 4)}
        </ol>
      </h1>
      {instructions && (
      <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: instructions }) }}></p>
    )}
    </>
  );
};

PageHeader.propTypes = {
  level1link: PropTypes.string,
  level1name: PropTypes.string,
  level2link: PropTypes.string,
  level2name: PropTypes.string,
  level3link: PropTypes.string,
  level3name: PropTypes.string,
  level4link: PropTypes.string,
  level4name: PropTypes.string,
  instructions: PropTypes.string
};

export default PageHeader;