import { FC, memo, useState } from "react";
import { Row } from "react-table";
import { Role } from "../../core/_models";

type Props = {
  row: Row<Role>;
};

const CustomRow: FC<Props> = ({ row }) => {
  return (
    <tr
      {...row.getRowProps()}
    >
      {row.cells.map((cell, index) => {
        // Create a unique key using the row index and cell column ID
        const key = `${row.id}-${cell.column.id}-${index}`;
        return (
          <td {...cell.getCellProps()} key={key} role="cell" className="text-gray-800 fw-bolder p-2">
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export default memo(CustomRow);
