/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
// Accessibility Notice:
// All jsx-a11y rules for keyboard accessibility and interactive elements have been applied in this file.
// However, there may need to be some sorting of tabindex on the final renders this icon is on. - CG 2024-10-04
import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../../helpers";

const CopyToClipboardIcon = ({
  handleCopyPassword,
  copied
}: {
  handleCopyPassword: () => void;
  copied: boolean;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        right: "50px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer"
      }}
      onClick={handleCopyPassword}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleCopyPassword();
        }
      }}
      role="button"
      tabIndex={0}
      aria-pressed={copied}
      aria-label={copied ? "Copied" : "Copy to Clipboard"}
    >
      {copied ? (
        <KTIcon iconName="check-square" iconType="outline" className="fs-1 text-success" />
      ) : (
        <div data-tooltip-id="copyToClipboard">
          <KTIcon iconName="copy" iconType="outline" className="iconBtn fs-1" />
          <Tooltip
            id="copyToClipboard"
            key="copyToClipboard"
            content="Copy to Clipboard"
            place="top"
            variant="dark"
            style={{ zIndex: 100 }}
          />
        </div>
      )}
    </div>
  );
};

export { CopyToClipboardIcon };
