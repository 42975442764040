import { useIntl } from "react-intl";
import CompanyLogo from "./CompanyLogo";

const CompanyLogoWrapper = () => {
  const intl = useIntl();

  return (
    <div className="row">
      <CompanyLogo />
    </div>
  );
};

export default CompanyLogoWrapper;
