import { ErrorMessage, Field, FieldArray } from "formik";
import { useIntl } from "react-intl";

const Step5 = (props: any) => {
  const intl = useIntl();

  return (
    <div className="w-100">
      <h1>{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP5" })}</h1>
      <hr />
      <div className="text-muted mt-3">
        {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP5.INSTRUCTIONS" })}
      </div>
      <div className="m-3">
        <FieldArray
          name="referrals"
          render={(arrayHelpers) => (
            <div>
              {props.values.referrals.map((field: any, index: any) => (
                <div key={index} className="form-group d-flex align-items-start mt-7">
                  <div className="p-2">
                    <label className="form-label" htmlFor={`referrals-${index}-domain`}>
                      {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP5.DOMAIN" })}
                    </label>
                    <Field
                      name={`referrals[${index}].host`}
                      id={`referrals-${index}-domain`}
                      className="form-control mb-2 mb-md-0"
                      placeholder=""
                      value={field.host}
                    />
                    <ErrorMessage
                      name={`referrals.${index}.host`}
                      render={(msg) => <p className="text-danger">{intl.formatMessage({ id: msg })}</p>}
                    />
                  </div>
                  <div className="p-2">
                    <label className="form-label" htmlFor={`referrals-${index}-display`}>
                      {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP5.SOURCE" })}
                    </label>
                    <Field
                      name={`referrals[${index}].display`}
                      id={`referrals-${index}-display`}
                      className="form-control mb-2 mb-md-0"
                      placeholder=""
                      value={field.display}
                    />
                    <ErrorMessage
                      name={`referrals.${index}.display`}
                      render={(msg) => <p className="text-danger">{intl.formatMessage({ id: msg })}</p>}
                    />
                  </div>
                  <div className="p-2">
                    <label className="form-label" htmlFor={`referrals-${index}-medium`}>
                      {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP5.MEDIUM" })}
                    </label>
                    <Field
                      name={`referrals[${index}].medium`}
                      id={`referrals-${index}-medium`}
                      className="form-control mb-2 mb-md-0"
                      placeholder=""
                      value={field.medium}
                    />
                    <ErrorMessage
                      name={`referrals.${index}.medium`}
                      render={(msg) => <p className="text-danger">{intl.formatMessage({ id: msg })}</p>}
                    />
                  </div>

                  <div className="p-2">
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className="btn btn-light-danger mt-md-8"
                      aria-label="Delete"
                    >
                      <i className="ki-duotone ki-trash fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                      </i>
                    </button>
                  </div>
                </div>
              ))}

              <div className="form-group d-flex my-3 mx-2">
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      host: "",
                      medium: "",
                      display: ""
                    })
                  }
                  className="btn btn-light-primary"
                >
                  <i className="ki-duotone ki-plus fs-3"></i>
                  {intl.formatMessage({ id: "BUTTON.ADD" })}
                </button>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export { Step5 };
