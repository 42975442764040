import { useEffect, useState, useMemo } from "react";
import { KTIcon } from "../../../../helpers";
import { Tooltip } from "react-tooltip";
import Actions from "./Actions";
import { useIntl } from "react-intl";
import { useInsJSFilesRequest } from "../core/InsJSFilesProvider";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { useRBAC } from "../../../roles-permissions/core/RBACProvide";
import ActionsCopy from "./ActionsCopy";

const InsentricJSFiles = () => {
  const intl = useIntl();
  const { allowedUserButtonClick } = useRBAC();

  const { isFetching, trackerFiles } = useInsJSFilesRequest();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortField, setSortField] = useState<string>("domain");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("ascending");

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const handleCopyEmbedCode = async (fileEmbedCode: any) => {
    try {
      await navigator.clipboard.writeText(fileEmbedCode);
      toast.success(intl.formatMessage({ id: "SETTINGS.TOAST.SUCCESS" }), {
        position: "top-center",
        autoClose: 2000
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sortData = (field: string) => {
    const isAsc = sortField === field && sortDirection === "ascending";
    setSortDirection(isAsc ? "descending" : "ascending");
    setSortField(field);
  };

  const renderSortIcon = (field: string) => {
    if (sortField !== field) {
      return <FontAwesomeIcon icon={faSort} aria-hidden="true" />;
    }
    return sortDirection === "ascending" ? (
      <FontAwesomeIcon icon={faSortUp} aria-hidden="true" />
    ) : (
      <FontAwesomeIcon icon={faSortDown} aria-hidden="true" />
    );
  };

  const sortedFiles = useMemo(() => {
    if (!trackerFiles) return [];

    return [...trackerFiles].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (aValue < bValue) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [trackerFiles, sortField, sortDirection]);

  return (
    <div className="col-12 p-3">
      {!isLoading ? (
        <div className="row">
          <div className="col-12 table-responsive">
            <table className="table align-middle gs-0 gy-4" role="table" aria-label="JS Files Table">
              <thead className="table-light">
                <tr className="fw-bold fs-7 text-muted bg-light py-4" role="row">
                  <th scope="col" className="text-center">
                    #
                  </th>
                  <th
                    className="sortable-column"
                    scope="col"
                    onClick={() => sortData("domain")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                  >
                    {intl.formatMessage({
                      id: "INSENTRICJS.DOMAINS"
                    })}{" "}
                    {renderSortIcon("domain")}
                  </th>
                  <th scope="col">
                    {intl.formatMessage({
                      id: "INSENTRICJS.EMBEDCODE"
                    })}
                  </th>
                  <th scope="col" className="text-center" style={{ width: "1%" }}>
                    {intl.formatMessage({
                      id: "INSENTRICJS.ACTIONS"
                    })}
                  </th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {sortedFiles.length > 0 ? (
                  sortedFiles.map((file: any, index: any) => (
                    <tr key={index} role="row">
                      <th scope="row" className="text-center align-middle">
                        {index + 1}
                      </th>
                      <td className="align-middle">
                        {file.domain}
                      </td>
                      <td className="align-middle">
                        <div className="copyIconContainer">
                          <button
                            onClick={() => handleCopyEmbedCode(file.embedCode)}
                            data-tooltip-id={`tooltip-${index}`}
                            className="code"
                            style={{ border: "none"}}
                            aria-label={`Copy embed code for ${file.domain}`}
                          >
                            {file.embedCode}
                          </button>
                          <Tooltip
                            id={`tooltip-${index}`}
                            key={`tooltip-${index}`}
                            content={intl.formatMessage({
                              id: "INSENTRICJS.EMBED"
                            })}
                            place="top"
                            variant="dark"
                            style={{
                              zIndex: 100,
                              fontFamily: "inter, helvetica, sans-serif"
                            }}
                          />
                        </div>
                      </td>
                      <td className="d-flex align-items-center">
                        <ActionsCopy
                          handleOnClick={() => handleCopyEmbedCode(file.embedCode)}
                          index={index}
                          domain={file.domain}
                        />
                        <Actions jsFileId={file.id} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "200px" }}
                        role="status"
                        aria-live="polite"
                      >
                        <h3>
                          {intl.formatMessage({
                            id: "INSENTRICJS.NOTRACKERS"
                          })}
                        </h3>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <span
          className="indicator-progress d-flex justify-content-center align-items-center pt-6 pb-6"
          role="status"
          aria-live="polite"
        >
          <span className="spinner-border spinner-border-lg align-middle ms-2" role="status" aria-hidden="true"></span>
          <span style={{ fontSize: 16, paddingLeft: 10 }}>
            {intl.formatMessage({
              id: "SETTINGS.ACCOUNT.INSENTRICJS.LOADINGTRACKERS"
            })}
          </span>
        </span>
      )}
    </div>
  );
};

export default InsentricJSFiles;
