import { useIntl } from "react-intl";

const FormFieldRunInTwoHours = (props: any) => {
  const intl = useIntl();
  return (
    <div className="m-3">
      <div className="mb-6">
        <div className="form-check form-switch form-check-custom form-check-solid justify-content-between align-items-center">
          <span className="fs-5 fw-bold" aria-hidden="true">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.RUNINTWOHOURS.TITLE"
            })}
          </span>
          <label
            htmlFor="mkto_qs_appender_run_in_two_hours"
            className="form-check-label sr-only"
          >
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.RUNINTWOHOURS.TITLE"
            })}
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            name="mkto_qs_appender_run_in_two_hours"
            id="mkto_qs_appender_run_in_two_hours"
            value={String(props.values.mkto_qs_appender_run_in_two_hours)}
            checked={props.values.mkto_qs_appender_run_in_two_hours}
            onChange={(e: any) => {
              props.setFieldValue(
                "mkto_qs_appender_run_in_two_hours",
                e.target.checked ? true : false
              );
            }}
            aria-describedby="mkto_qs_appender_run_in_two_hours_description"
          />
        </div>
        <div
          id="mkto_qs_appender_run_in_two_hours_description"
          className="text-muted mt-3"
        >
          {intl.formatMessage({
            id: "SETTINGS.MKTO.QSAPPEND.RUNINTWOHOURS.DESC"
          })}
        </div>
      </div>
      {!!props.errors.mkto_qs_appender_run_in_two_hours &&
      props.touched.mkto_qs_appender_run_in_two_hours ? (
        <p className="text-danger">
          {intl.formatMessage({
            id: props.errors.mkto_qs_appender_run_in_two_hours
          })}
        </p>
      ) : null}
    </div>
  );
};

export default FormFieldRunInTwoHours;
