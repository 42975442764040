import { Dispatch, SetStateAction } from "react";

export type ID = undefined | null | string;

export type fieldListItems = {
  host: string;
  medium: string;
  display: string;
};

export type validDomainItems = {
  label: string;
  value: string;
};

export type reservedStringsItems = {
  label: string;
  value: string;
};

export type InsentricJSFile = {
  maindomain: string;
  cookie_months: number;
  initialConversion: boolean;
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
  pmfcSource: string;
  pmfcMedium: string;
  pmfcCampaign: string;
  pmfcTerm: string;
  pmfcContent: string;
  tempSource: string;
  tempMedium: string;
  tempCampaign: string;
  tempTerm: string;
  tempContent: string;
  validdomains: Array<validDomainItems>;
  reservedstrings: Array<reservedStringsItems>;
  referrals: Array<fieldListItems>;
};

export type FieldItem = {
  displayName: string;
  isCustom: boolean;
  insentricStatus: "available" | "unavailable" | "default";
  length: string;
  name: string;
  picklistValues: string[];
  type: string;
};

export const initialValues = {
  maindomain: "",
  cookie_months: 6,
  initialConversion: false,
  source: "",
  medium: "",
  campaign: "",
  term: "",
  content: "",
  pmfcSource: "",
  pmfcMedium: "",
  pmfcCampaign: "",
  pmfcTerm: "",
  pmfcContent: "",
  tempSource: "",
  tempMedium: "",
  tempCampaign: "",
  tempTerm: "",
  tempContent: "",
  validdomains: [],
  reservedstrings: [],
  referrals: [{ host: "", medium: "", display: "" }]
};

export type InsJSFilesContextProps = {
  trackerFiles: Array<any>;
  isFetching: boolean;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  setRefetch: Dispatch<SetStateAction<boolean>>;
  isInitialAndConversion?: boolean | undefined;
  setIsInitialAndConversion: Dispatch<SetStateAction<boolean | undefined>>;
};

export const initialInsJSFilesRequest = {
  trackerFiles: [],
  isFetching: true,
  itemIdForUpdate: undefined,
  setItemIdForUpdate: () => {},
  setRefetch: () => {},
  setIsInitialAndConversion: () => {}
};
