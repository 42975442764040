import { OBJECT_KEYS } from "../../../helpers/apiHelpers";

export const objects = [
    { label: "Account", value: OBJECT_KEYS.SFDC.ACCOUNT },
    { label: "Contact", value: OBJECT_KEYS.SFDC.CONTACT },
    { label: "Lead", value: OBJECT_KEYS.SFDC.LEAD },
    { label: "Opportunity", value: OBJECT_KEYS.SFDC.OPPORTUNITY },
    { label: "Campaign", value: OBJECT_KEYS.SFDC.CAMPAIGN },
    { label: "Campaign Member", value: OBJECT_KEYS.SFDC.CAMPAIGNMEMBER },
    { label: "Task", value: OBJECT_KEYS.SFDC.TASK }
  ];