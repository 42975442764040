/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { ILayout, useLayout } from "../../core";
import { useIntl } from "react-intl";

const Footer: React.FC = () => {
  const intl = useIntl();
  const { config } = useLayout();

  useEffect(() => {
    updateDOM(config);
  }, [config]);

  return (
    <>
      <div className="text-dark order-2 order-md-1">
        <span className="text-muted fw-semibold me-1">
          {new Date().getFullYear().toString()}&copy;
          <a
            href="https://insentric.com/"
            target="_blank"
            className="text-gray-800 text-hover-primary"
          >
            {intl.formatMessage({ id: "FOOTER.LINKTITLE.INSENTRIC" })}
          </a>{" "}
          {intl.formatMessage({ id: "FOOTER.BOILERPLATE.RIGHTSRESERVED" })}
        </span>
      </div>

      <ul className="menu menu-hover-primary fw-semibold order-1">
        <li className="menu-item">
          <a
            href="https://insentric.com/terms-of-use/"
            target="_blank"
            className="menu-link px-2"
          >
            {intl.formatMessage({ id: "FOOTER.LINKTITLE.TERMS" })}
          </a>
        </li>

        <li className="menu-item">
          <a
            href="https://support.insentric.com/"
            target="_blank"
            className="menu-link px-2"
          >
            {intl.formatMessage({ id: "FOOTER.LINKTITLE.SUPPORT" })}
          </a>
        </li>
        <li className="menu-item">
          <a href="/app/acknowledgements" className="menu-link px-2">
            {intl.formatMessage({ id: "FOOTER.LINKTITLE.3PACK" })}
          </a>
        </li>
        <li className="menu-item">
          <a
            href="https://insentric.com/privacy-policy/"
            target="_blank"
            className="menu-link px-2"
          >
            {intl.formatMessage({ id: "FOOTER.LINKTITLE.PRIVACYPOLICY" })}
          </a>
        </li>
      </ul>
    </>
  );
};

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add("data-kt-app-footer-fixed", "true");
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add("data-kt-app-footer-fixed-mobile", "true");
  }
};

export { Footer };
