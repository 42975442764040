import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

/**
 * devKey is used to allow remove cookie doimain and SSL restricitions for local development (only for dev environment)
 */
const devKey = process.env.REACT_APP_API_DEV_KEY
/**
 * React sets NODE_ENV to 'development' when running with npm start and 'production' when running with npm build
 */
const environment = process.env.NODE_ENV


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return object => { result: boolean } (Is Email in DB)
export function getUser() {
  return axios.get('/auth/me')
}

export function login(idToken: string, csrfToken : string) {

  /**
   * Authentication keys for login
   */
  const authKeys : {
    [key : string] : string
  } = {
    idToken,
    csrfToken,
  }

  const headers : {
    [key : string] : string
  } = {}

  /**
   * Add devKey to authKeys if it is defined and environment is development to enable devMode and bypass cookie restricitions
   */
  if (environment === 'development' && devKey) {
    headers['x-insentric-dev-key'] = devKey
  }

  return axios.post('/auth/login', authKeys, { headers })
}

export function sessionLogout(){

  const headers : {
    [key : string] : string
  } = {}

  /**
   * Add devKey to authKeys if it is defined and environment is development to enable devMode and bypass cookie restricitions
   */
  if (environment === 'development' && devKey) {
    headers['x-insentric-dev-key'] = devKey
  }

  return axios.get('/auth/sessionLogout', { headers })
}
