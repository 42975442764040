import { FC } from 'react';
import { useAuth } from '../../../../pages/auth';
import Avatar from 'react-avatar';
import md5  from 'md5';

const UserAvatar: FC = () => {
  const { currentUser } = useAuth();

  if (!currentUser || !currentUser.email) {
    return <Avatar name="Unknown User" size="35" round="6" />;
  }


  const emailHash = md5(currentUser.email);
  const firstName = currentUser.first_name || 'Unknown';
  const lastName = currentUser.last_name || 'User';
  const fullName = `${firstName} ${lastName}`;

  return (
    <Avatar 
      md5Email={emailHash}
      name={fullName}
      size="35"
      round="6"
    />
  );
};

export { UserAvatar };