import { Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage } from "formik";

type SwitchFieldProps = {
  id: string;
  label: string;
  isInvalid?: boolean;
  description?: string;
  optional?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onChange: () => void;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Switch type field component Bootstrap's Form.Check component to render a switch input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} label - The label for the field
 * @prop {boolean} [isInvalid=false] - Whether the field is invalid or not
 * @prop {string} [description] - The description for the field
 * @prop {boolean} [optional=false] - Whether the field is optional or not
 * @prop {boolean} [disabled=false] - Whether the field is disabled or not
 * @prop {string} [checked=true] - Whether the field is checked or not
 * @prop {() => void} onChange - The function to call when the field changes
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const SwitchField = ({
  id,
  label,
  isInvalid = false,
  description,
  optional = false,
  disabled = false,
  checked = true,
  onChange,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: SwitchFieldProps) => {
  return (
    <BootstrapForm.Group className="mb-3 py-6">
      <div style={{ display: "flex", alignItems: "center" }}>
        <BootstrapForm.Check
          type="switch"
          id={id}
          name={id}
          isInvalid={isInvalid}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
          {...props}
        />
        {label && (
          <BootstrapForm.Label
            htmlFor={id}
            className={hideLabel ? "sr-only" : ""}
            style={{ marginLeft: "0.75em" }}
            id={`${id}-label`}
          >
            {`${label}${optional ? " (optional)" : ""}`}
          </BootstrapForm.Label>
        )}
      </div>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default SwitchField;
