import { FC } from "react";
import { KTIcon } from "../../../../helpers";
import { useIntl } from "react-intl";

type LockAlertProps = {
  alert: "capsLock" | "numLock" | "scrollLock";
};

const LockAlert: FC<LockAlertProps> = ({ alert }) => {
  const intl = useIntl();

  const ERROR_MESSAGES = {
    capsLock: intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.ALERT.ERROR.CAPSLOCK" }),
    numLock: intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.ALERT.ERROR.NUMLOCK" }),
    scrollLock: intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.ALERT.ERROR.SCROLLLOCK" })
  };

  return (
    <div
      id="capslock-warning"
      className="d-flex align-items-center bg-light-warning rounded border border-warning my-3 p-2 gap-2"
    >
      <KTIcon iconName="information-3" iconType="outline" className="iconBtn fs-1 text-warning text-warning-dark" />
      <span className="text-warning text-warning-dark" dangerouslySetInnerHTML={{ __html: ERROR_MESSAGES[alert] }}></span>
    </div>
  );
};

export { LockAlert };
