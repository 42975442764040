import React, { useEffect, useRef, useCallback, ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

interface HandleTooltipProps {
  value: any;
  children: React.ReactNode;
  visible: boolean;
  tipFormatter?: (val: any) => React.ReactNode;
}

const HandleTooltip: React.FC<HandleTooltipProps> = ({
  value,
  children,
  visible,
  tipFormatter = (val) => `${val} %`,
  ...restProps
}) => {
  const rafRef = useRef<number | null>(null);
  const tooltipId = `tooltip-${value}`; // Unique ID for each tooltip

  const cancelKeepAlign = useCallback(() => {
    if (rafRef.current !== null) {
      cancelAnimationFrame(rafRef.current);
    }
  }, []);

  const keepAlign = useCallback(() => {
    rafRef.current = requestAnimationFrame(() => {
      // Custom alignment adjustments
    });
  }, []);

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible, keepAlign, cancelKeepAlign]);

  return (
    <>
      <span data-tooltip-id={tooltipId} data-tooltip-content={String(tipFormatter(value))}>
        {children as React.ReactElement}
      </span>
      <ReactTooltip id={tooltipId} place="top" isOpen={visible} {...restProps} />
    </>
  );
};

HandleTooltip.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  tipFormatter: PropTypes.func,
};

export const handleRender = (node: React.ReactNode, props: HandleTooltipProps) => {
  return (
    <HandleTooltip value={props.value} visible={props.visible}>
      {node}
    </HandleTooltip>
  );
};
