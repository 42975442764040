import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import { FC } from "react";
import { useIntl } from "react-intl";

type ShowPasswordIconProps = {
  id: string;
  showPassword: boolean;
  setShowPassword: () => void;
};

const ShowPasswordIcon: FC<ShowPasswordIconProps> = ({ id, showPassword, setShowPassword }) => {
  const intl = useIntl();

  return (
    <div
      id={id}
      className="profile-show-password-icon"
      onClick={setShowPassword}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          setShowPassword();
        }
      }}
      role="button"
      tabIndex={0}
      aria-pressed={showPassword}
      aria-label={
        showPassword
          ? intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.HIDEPASSWORD" })
          : intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.SHOWPASSWORD" })
      }
      data-tooltip-id="showHidePassword"
    >
      <KTIcon iconName={showPassword ? "eye-slash" : "eye"} iconType="outline" className="iconBtn fs-1" />
      <Tooltip
        id="showHidePassword"
        key="showHidePassword"
        content={
          showPassword
            ? intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.HIDEPASSWORD" })
            : intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.SHOWPASSWORD" })
        }
        place="top"
        variant="dark"
        className="zindex-tooltip"
      />
    </div>
  );
};

export { ShowPasswordIcon };
