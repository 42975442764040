import axios, { AxiosError } from 'axios';

/**
 * Maps keys to specific endpoint URLs.
 */
const endpointMap: Record<string, string> = {
  'insentric-mkto': '/configs/insentric-mkto',
  'insentric-mkto-qs-append': '/configs/insentric-mkto-qs-append',
  'insentric-sfdc': '/configs/insentric-sfdc',
  'users': '/users'
};

/**
 * Performs a GET request to a specified URL using axios. The URL is determined based on the input parameter.
 * If a matching key is provided, it uses the mapped URL; otherwise, it treats the input as a URL.
 * 
 * @param key - A key to determine which URL to send the GET request to, or a direct URL.
 * @returns The response data from the GET request if successful; otherwise, shows a toast notification and returns `null`.
 * @throws Will log an error if the GET request fails.
 */
export const apiGet = async (key: string): Promise<any> => {
  // Use the mapped endpoint for the key, or treat the key as a URL if no match is found.
  const endpoint = endpointMap[key] || key;

  try {
    const { data: response } = await axios.get(endpoint); 
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error(axiosError);

    let errorMessage: string;
    if (typeof axiosError.response?.data === 'string') {
      errorMessage = axiosError.response.data;
    } else if (axiosError.response?.data && typeof axiosError.response.data === 'object') {
      errorMessage = JSON.stringify(axiosError.response.data);
    } else {
      errorMessage = "An error occurred";
    }

    return null;
  }
};

export type MKTOObject = 'LEAD' | 'COMPANY' | 'OPPORTUNITY' | 'PROGRAMMEMBER';
export type SFDCObject = 'LEAD' | 'ACCOUNT' | 'OPPORTUNITY' | 'CAMPAIGN' | 'CAMPAIGNMEMBER' | 'CONTACT' | 'TASK';

export const OBJECT_KEYS : {
  MKTO: Record<MKTOObject, string>;
  SFDC: Record<SFDCObject, string>;
}  = {
  MKTO : {
    LEAD : 'lead',
    PROGRAMMEMBER : 'program_member',
    COMPANY : 'company',
    OPPORTUNITY : 'opportunity'

  },
  SFDC : {
    LEAD : 'lead',
    ACCOUNT : 'account',
    OPPORTUNITY : 'opportunity',
    CAMPAIGN : 'campaign',
    CAMPAIGNMEMBER : 'campaign_member',
    CONTACT : 'contact',
    TASK : 'task'
  }
}
