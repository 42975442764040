/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import MktoSettings from "./config-components/MktoSettings";
import MktoQSAppenderSettings from "./config-components/MktoQSAppender/MktoQSAppenderSettings";
import MktoActivityTypes from "./config-components/MktoActivityTypes";
import MktoLifecycleStages from "./config-components/MktoLifecycleStages";
import MktoProgressionStatusMap from "./config-components/MktoProgressionStatusMap";
import Secrets from "./config-components/Secrets";

const MktoConfigWrapper: FC = () => {

  return (
    <>
      {/* begin::Row */}
      <div className="container-fluid px-3 form-container">
      <div className="w-100">
        <div className="col-12">
          <Routes>
            <Route path="/marketo_settings" element={<MktoSettings />} />
            <Route
              path="/marketo_qs_appender_settings"
              element={<MktoQSAppenderSettings />}
            />
            <Route
              path="/marketo_activity_types"
              element={<MktoActivityTypes />}
            />
            <Route
              path="/marketo_lifecycle_stages"
              element={<MktoLifecycleStages />}
            />
            <Route
              path="/marketo_progression_status_map"
              element={<MktoProgressionStatusMap />}
            />
            <Route path="/secrets" element={<Secrets />} />
          </Routes>
        </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  );
};

export { MktoConfigWrapper };
