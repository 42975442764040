import { useIntl } from "react-intl";

const GenSettingsHeader = () => {
  const intl = useIntl();
  return (
    <div className="card-header">
      <h2 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-1 mb-1">
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.GENERALSETTINGS"
          })}
        </span>
      </h2>
    </div>
  );
};

export default GenSettingsHeader;
