/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../helpers";
import { Languages } from "./components/Languages";
import { useIntl } from "react-intl";

const AuthLayout: React.FC = () => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {
      // Cleanup function to reset background image
      document.body.style.backgroundImage = "";
    };
  }, []);


  return (
      <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <div className="w-100 w-md-500px p-10">
              <Outlet />
            </div>
          </div>

          <div className="d-flex flex-center flex-wrap px-5">
            <Languages />

            <div className="d-flex fw-semibold text-primary fs-base">
              <a
                href="https://insentric.com/terms-of-use/"
                className="px-5"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({ id: "FOOTER.LINKTITLE.TERMS" })}
              </a>

              <a
                href="https://support.insentric.com/"
                className="px-5"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({ id: "FOOTER.LINKTITLE.SUPPORT" })}
              </a>

              <a
                href="https://insentric.com/privacy-policy/"
                className="px-5"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({ id: "FOOTER.LINKTITLE.PRIVACYPOLICY" })}
              </a>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
          style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/misc/mopza-bg.jpg")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#2E4EA2",
        }}
        >
          <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
            <Link to="/" className="d-lg-none">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/default-white.svg")}
                className="theme-dark-show h-45px"
              />
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/default-white.svg")}
                className="theme-light-show h-45px"
              />
            </Link>

            <img
              className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20 d-none d-lg-block d-xl-block"
              src={toAbsoluteUrl("/media/misc/mopza-750px.png")}
              alt="Auth Screens"
            />

            <h1 className="text-white fs-2qx fw-bolder text-center mb-7 w-lg-75 w-md-50 d-none d-lg-block d-xl-block">
              {intl.formatMessage({ id: "AUTH.TITLE" })}
            </h1>

            <div className="text-white fs-base text-center w-lg-75 w-md-50 d-none d-lg-block d-xl-block">
              {intl.formatMessage({ id: "AUTH.DESCRIPTION" })}
              <a href="https://www.accelevents.com/e/MOps-Apalooza-2024?sessionId=340313#agenda" className="opacity-75-hover text-warning fw-bold me-1">
                {intl.formatMessage({ id: "AUTH.FEATURE" })}
              </a>
              {intl.formatMessage({ id: "AUTH.UPCOMING" })}
             
            </div>
          </div>
        </div>
      </div>
  );
};

const AuthPage: React.FC = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      {/* <Route path="registration" element={<Registration />} /> */}
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
