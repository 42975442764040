import { useEffect, useState } from "react";
import { generate } from "generate-password-browser";
import { forbiddenWords } from "./_forbiddenWords";

type KeyLock = "CapsLock" | "NumLock" | "ScrollLock";

const useKeyLock = (key: KeyLock) => {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => setToggled((pToggled) => event.getModifierState?.(key) ?? pToggled);

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [key]);

  return toggled;
};

const passwordGenerator = () => {
  const password = generate({
    length: 24,
    numbers: true,
    lowercase: true,
    uppercase: true,
    symbols: true
  });
  return password;
};

const checkPassword = (value: string, confirmValue: string) => {
  const checkResults = {
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isNotEmpty: false,
    isNotForbidden: true,
    isLongEnough: false,
    isMatching: false
  };

  if (value.length > 0) {
    checkResults.isNotEmpty = true;
  } else {
    checkResults.isNotEmpty = false;
  }

  if (value.length >= 8) {
    checkResults.isLongEnough = true;
  } else {
    checkResults.isLongEnough = false;
  }

  const specialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  if (specialChar.test(value)) {
    checkResults.hasSpecialChar = true;
  } else {
    checkResults.hasSpecialChar = false;
  }

  const upperCase = /[A-Z]/;

  if (upperCase.test(value)) {
    checkResults.hasUpperCase = true;
  } else {
    checkResults.hasUpperCase = false;
  }

  const lowerCase = /[a-z]/;

  if (lowerCase.test(value)) {
    checkResults.hasLowerCase = true;
  } else {
    checkResults.hasLowerCase = false;
  }

  const number = /[0-9]/;

  if (number.test(value)) {
    checkResults.hasNumber = true;
  } else {
    checkResults.hasNumber = false;
  }

  const forbiddenWordsRegex = new RegExp(forbiddenWords.join("|"), "i");

  if (forbiddenWordsRegex.test(value)) {
    checkResults.isNotForbidden = false;
  } else {
    checkResults.isNotForbidden = true;
  }

  if (value === confirmValue && confirmValue.length > 0 && value.length > 0) {
    checkResults.isMatching = true;
  } else {
    checkResults.isMatching = false;
  }

  return checkResults;
};

const getParameterByName = (name: any) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = "[\\?&]" + name + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(window.location.href);
  if (results == null) return "";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
};

const deleteAllQueryParams = () => {
  const url = new URL(window.location.href);

  const modeValue = url.searchParams.get("mode");
  const oobCodeValue = url.searchParams.get("oobCode");
  const continueUrlValue = url.searchParams.get("continueUrl");
  const langValue = url.searchParams.get("lang");
  const apiKeyValue = url.searchParams.get("apiKey");

  if (modeValue) {
    url.searchParams.delete("mode");
  }
  if (oobCodeValue) {
    url.searchParams.delete("oobCode");
  }
  if (continueUrlValue) {
    url.searchParams.delete("continueUrl");
  }
  if (langValue) {
    url.searchParams.delete("lang");
  }
  if (apiKeyValue) {
    url.searchParams.delete("apiKey");
  }

  history.replaceState(history.state, "", url.href);
};

export { useKeyLock, passwordGenerator, checkPassword, getParameterByName, deleteAllQueryParams };
