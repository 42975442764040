import { ErrorMessage, useField } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import styles from "../style/LoginMethodRadio.module.css";

export type LoginMethodRadioProps = {
  id: string;
  label: string;
  options?: { value: string; label: string; sublabel?: string | null }[];
  classes?: string;
};

/**
 * Login method field component that uses Bootstrap's Form.Check component to render styled radio input fields.
 *
 * @prop {string} id - The id of the field
 * @prop {string} label - The label for the field
 * @prop {{ value: string; label: string; sublabel?: string | null }[]} [options=[]] - The options for the field
 * @prop {string} [classes] - Any additional classes to apply to the field
 */

export const LoginMethodRadio = ({ id, label, options, classes }: LoginMethodRadioProps) => {
  const [field, meta, helpers] = useField(id);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <>
      <BootstrapForm.Label>{label}</BootstrapForm.Label>
      <div className="d-flex align-items-start flex-column flex-lg-row">
        {options?.map((option) => (
          <div key={option.value}>
            <BootstrapForm.Check
              type="radio"
              id={`${option.value}`}
              className={
                value !== option.value ? `${classes} ${styles.checkbox}` : `${classes} ${styles.checkboxActive}`
              }
            >
              <BootstrapForm.Check.Input
                type="radio"
                name={option.value}
                value={option.value}
                checked={value === option.value}
                onChange={() => setValue(option.value)}
                style={{ cursor: "pointer" }}
              />
              <BootstrapForm.Check.Label style={{ cursor: "pointer" }}>
                <div>
                  <div className={styles.title}>{option.label}</div>
                  {option.sublabel && <div className="loginMethodSubtitle">{option.sublabel}</div>}
                </div>
              </BootstrapForm.Check.Label>
            </BootstrapForm.Check>
          </div>
        ))}
      </div>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
    </>
  );
};

export default LoginMethodRadio;
