import { useIntl } from "react-intl";
import { TimezoneSelect } from "../../../../layout/components/timezone";
import { useEffect } from "react";

const GenSettingsTimezone = ({
  setFieldValue,
  timezone,
  setTimezone
}: {
  setFieldValue: (field: string, value: any) => void;
  timezone: string;
  setTimezone: (value: string) => void;
}) => {
  const intl = useIntl();

  useEffect(() => {
    setFieldValue("company_main_timezone", timezone);
  }, [timezone, setFieldValue]);

  return (
    <div className="row">
      <div className="col-lg-4 p-3 mb-3">
        <span className="fw-bold fs-4 text-muted" id="timezone-label">
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.COMPANYTIMEZONE"
          })}
        </span>
      </div>
      <div className="col-lg-8 p-3 mb-3">
        <TimezoneSelect
          value={timezone}
          onChange={setTimezone}
          ariaLabelledBy="timezone-label"
        />
      </div>
    </div>
  );
};

export default GenSettingsTimezone;
