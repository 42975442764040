import { Form as BootstrapForm } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import { enUS } from "date-fns/locale/en-US";
import { fr } from "date-fns/locale/fr";
import { es } from "date-fns/locale/es";
import { de } from "date-fns/locale/de";
import { ja } from "date-fns/locale/ja";
import { zhCN } from "date-fns/locale/zh-CN";
import { useIntl } from "react-intl";

// Register locales
registerLocale("en", enUS);
registerLocale("fr", fr);
registerLocale("es", es);
registerLocale("de", de);
registerLocale("ja", ja);
registerLocale("zh", zhCN);

type DateFieldProps = {
  id: string;
  label?: string;
  description?: string;
  classes?: string;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Date picker component that uses the DatePicker "react-datepicker" component to render a date picker input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} [label] - The label for the field
 * @prop {string} [description] - The description for the field
 * @prop {string} [classes] - Any additional classes to apply to the field
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const DateField = ({
  id,
  label,
  description,
  classes,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: DateFieldProps) => {
  const [, meta, helpers] = useField(id);
  const { value } = meta;
  const { setValue } = helpers;
  const intl = useIntl();

  const handleChange = (date: Date | null) => {
    setValue(date);
  };

  return (
    <BootstrapForm.Group controlId={id} className="mb-3">
      {!hideLabel && label && <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>}
      <div className="m-10 ml-0">
        <DatePicker
          id={id}
          selected={value && new Date(value)}
          onChange={handleChange}
          className={classes ? classes : ""}
          locale={intl.locale}
          placeholderText={intl.formatMessage({ id: "FORM.SELECT" })}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
          {...props}
        />
      </div>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default DateField;
