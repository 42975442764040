import { SingleSelectDropdown } from "../../../../../layout/components/form";
import { useIntl } from "react-intl";

const Step3 = (props: any) => {
  const intl = useIntl();

  return (
    <div className="w-100">
      <h1>
        {intl.formatMessage({
          id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP3"
        })}
      </h1>
      <div className="text-muted mt-3">
        {intl.formatMessage({
          id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP3.INSTRUCTIONS"
        })}
      </div>
      <hr />
      <div className="w-100 d-flex flex-column">
        <div className="m-3 flex-fill">
          <h2 id="pmfcSource-label">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE"
            })}
          </h2>
          <div className="text-muted my-2" id="pmfcSource-description">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE.INSTRUCTIONS"
            })}
          </div>
          <SingleSelectDropdown
            id="pmfcSource"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            classes={props.errors.pmfcSource && props.touched.pmfcSource ? "border-danger" : ""}
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcSource-label"
            ariaDescribedBy="pmfcSource-description"
          />
          {!!props.errors.pmfcSource && props.touched.pmfcSource ? (
            <p className="text-danger">{intl.formatMessage({ id: props.errors.pmfcSource })}</p>
          ) : null}
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcMedium-label">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM"
            })}
          </h2>
          <div className="text-muted my-2" id="pmfcMedium-description">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM.INSTRUCTIONS"
            })}
          </div>
          <SingleSelectDropdown
            id="pmfcMedium"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            classes={props.errors.pmfcMedium && props.touched.pmfcMedium ? "border-danger" : ""}
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcMedium-label"
            ariaDescribedBy="pmfcMedium-description"
          />
          {!!props.errors.pmfcMedium && props.touched.pmfcMedium ? (
            <p className="text-danger">{intl.formatMessage({ id: props.errors.pmfcMedium })}</p>
          ) : null}
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcCampaign-label">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN"
            })}
          </h2>
          <div className="text-muted my-2" id="pmfcCampaign-description">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN.INSTRUCTIONS"
            })}
            .
          </div>
          <SingleSelectDropdown
            id="pmfcCampaign"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            classes={props.errors.pmfcCampaign && props.touched.pmfcCampaign ? "border-danger" : ""}
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcCampaign-label"
            ariaDescribedBy="pmfcCampaign-description"
          />
          {!!props.errors.pmfcCampaign && props.touched.pmfcCampaign ? (
            <p className="text-danger">{intl.formatMessage({ id: props.errors.pmfcCampaign })}</p>
          ) : null}
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcTerm-label">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM"
            })}
          </h2>
          <div className="text-muted my-2" id="pmfcTerm-description">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM.INSTRUCTIONS"
            })}
          </div>
          <SingleSelectDropdown
            id="pmfcTerm"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            classes={props.errors.pmfcTerm && props.touched.pmfcTerm ? "border-danger" : ""}
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcTerm-label"
            ariaDescribedBy="pmfcTerm-description"
          />
          {!!props.errors.pmfcTerm && props.touched.pmfcTerm ? (
            <p className="text-danger">{intl.formatMessage({ id: props.errors.pmfcTerm })}</p>
          ) : null}
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcContent-label">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT"
            })}
          </h2>
          <div className="text-muted my-2" id="pmfcContent-description">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT.INSTRUCTIONS"
            })}
          </div>
          <SingleSelectDropdown
            id="pmfcContent"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            classes={props.errors.pmfcContent && props.touched.pmfcContent ? "border-danger" : ""}
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcContent-label"
            ariaDescribedBy="pmfcContent-description"
          />
          {!!props.errors.pmfcContent && props.touched.pmfcContent ? (
            <p className="text-danger">{intl.formatMessage({ id: props.errors.pmfcContent })}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { Step3 };
