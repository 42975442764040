export const FLAG_REPLACEMENTS = [
  [" ", "-"],
  ["(", ""],
  [")", ""],
  [",", ""],
  ["ç", "c"],
  ["ô", "o"],
  ["'", "-"],
  ["å", "a"],
  [".", "-"],
  ["é", "e"]
];