import ProfileSettingsForm from "./ProfileSettingsForm";

const ProfileSettings = () => {
  return (
    <div className="card mb-5 mb-xxl-8">
      <div className="card-body">
        <ProfileSettingsForm />
      </div>
    </div>
  );
};
export default ProfileSettings;
