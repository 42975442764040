import React, { memo } from "react";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { useRBAC } from "../../../pages/roles-permissions/core/RBACProvide";

interface FieldProps {
  label: string;
  id: string;
  description?: string;
  optional?: boolean;
  options?: Array<any>;
  fieldComponent: React.ElementType;
  showRefreshButton?: boolean | undefined | null;
  refreshType?: string;
  disabled?: boolean;
  hideShowPasswordIcon?: boolean;
}

interface FormProps<T> {
  fields: FieldProps[];
  initialValues: T;
  validationSchema?: Yup.ObjectSchema<any>;
  handleSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  disabled?: boolean;
  className?: string;
}

function Form<T extends Record<string, any>>({
  fields,
  initialValues,
  validationSchema,
  handleSubmit,
  disabled = false,
  className = ""
}: FormProps<T>): React.ReactElement {
  const intl = useIntl();
  const { allowedUserButtonClick } = useRBAC();

  return (
    <Formik<T> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ touched, errors, isSubmitting }) => (
        <>
          <FormikForm className={`form ${className}`}>
            {" "}
            {/* Apply custom className */}
            {fields.map(
              ({
                label,
                id,
                description,
                optional,
                options,
                fieldComponent: FieldComponent,
                showRefreshButton = false,
                refreshType = "",
                disabled,
                hideShowPasswordIcon = false
              }) => (
                <FieldComponent
                  key={id}
                  id={id}
                  label={label}
                  description={description}
                  options={options}
                  optional={optional}
                  isInvalid={touched[id] && errors[id]}
                  showRefreshButton={showRefreshButton}
                  refreshType={showRefreshButton ? refreshType : undefined}
                  disabled={disabled}
                  hideShowPasswordIcon={hideShowPasswordIcon}
                />
              )
            )}
            <Button
              style={{ marginTop: 20, marginBottom: 10 }}
              type="submit"
              variant="primary"
              disabled={isSubmitting || disabled || !allowedUserButtonClick}
            >
              {isSubmitting ? intl.formatMessage({ id: "FORM.SUBMITTING" }) : intl.formatMessage({ id: "FORM.SUBMIT" })}
            </Button>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default memo(Form) as <T extends Record<string, any>>(props: FormProps<T>) => React.ReactElement;
