import { FLAG_REPLACEMENTS } from "../data/flagReplacement";
import zoneToCC from "../data/zoneToCC.json";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const replaceFlagChars = (label) => {
  let flag = label.toLowerCase();
  FLAG_REPLACEMENTS.forEach(([target, replacement]) => {
    flag = flag.replaceAll(target, replacement);
  });
  return flag.split(":")[0];
};

const computeTimezoneGroups = (timezones, countryNames, englishCountryNames, intl) => {
  const timezonesGroupedByCC = Object.entries(timezones).reduce((output, [, timezoneData]) => {
    if (zoneToCC[timezoneData.id]) {
      const CC = zoneToCC[timezoneData.id];
      output[CC] = output[CC] || [];
      output[CC].push(timezoneData);
    }
    return output;
  }, {});

  const groups = Object.entries(timezonesGroupedByCC)
    .reduce((output, [CC, zonesByCountry]) => {
      const flag = replaceFlagChars(englishCountryNames[CC]);
      output.push({
        text: `${countryNames[CC]}: `,
        country: flag,
        children: zonesByCountry,
        firstNOffset: zonesByCountry[0].nOffset
      });
      return output;
    }, [])
    .sort((a, b) => a.text.localeCompare(b.text));

  const guessedTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (guessedTimezoneName) {
    const guessedTimezone = timezones[guessedTimezoneName];
    if (guessedTimezone) {
      groups.unshift({
        text: intl.formatMessage({ id: "TIMEZONES.LOCAL", defaultMessage: "Local:" }),
        children: [guessedTimezone],
        firstNOffset: guessedTimezone.nOffset,
        firstOffset: guessedTimezone.offset
      });
    }
  }

  return groups;
};

const getOptionFromValue = (options, value) => {
  for (const timezoneGroup of options) {
    for (const timezone of timezoneGroup.options) {
      if (timezone.value === value) {
        return timezone;
      }
    }
  }
  return null;
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    margin: 8
  }),
  menu: () => ({
    boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.08)"
  })
};

const compareTimezoneGroups = (a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0);

const has = (collection, key) => key in collection;

export { computeTimezoneGroups, getOptionFromValue, selectStyles, compareTimezoneGroups, has, replaceFlagChars };
