/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
// Accessibility Notice:
// All jsx-a11y rules for keyboard accessibility and interactive elements have been applied in this file.
// However, there may need to be some sorting of tabindex on the final renders this icon is on. - CG 2024-10-04
import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../../helpers";

const GenerateNewPasswordIcon = ({
  handleGenerateNewPassword
}: {
  handleGenerateNewPassword: () => void;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        right: "90px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer"
      }}
      data-tooltip-id="genNewPass"
      onClick={handleGenerateNewPassword}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleGenerateNewPassword();
        }
      }}
      role="button"
      tabIndex={0}
      aria-label="Generate New Password"
    >
      <KTIcon
        iconName="arrows-circle"
        iconType="outline"
        className="iconBtn fs-1"
      />
      <Tooltip
        id="genNewPass"
        key="genNewPass"
        content="Generate New Password"
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export { GenerateNewPasswordIcon };
