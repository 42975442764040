import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import * as Yup from "yup";
import { useIntl } from "react-intl";

import Form from "../../../layout/components/form/Form";
import { MultiSelectDropdown } from "../../../layout/components/form";

import InsSpinner from "../../../layout/components/spinner/InsSpinner";

import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import PageHeader from "../../../layout/components/content/PageHeader";
import { useRBAC } from "../../roles-permissions/core/RBACProvide";

export default function MktoActivityTypes() {
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [activityTypes, setActivityTypes] = useState<any>(undefined);
  const [mktoDataFetchStatus, setMktoDataFetchStatus] = useState<any>(undefined);
  const intl = useIntl();
  const { allowedUserButtonClick } = useRBAC();

  const handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: any }) => {
    if (!allowedUserButtonClick) {
      toast.error(intl.formatMessage({ id: "ERROR.MESSAGE.NOTALLOWED.SUBMIT" }), toastSettings("error"));
      return;
    }

    setSubmitting(true);

    try {
      await axios.post("/configs/insentric-mkto", {
        excluded_activity_type_ids: values.mkto_excluded_activities,
        custom_activity_type_ids: values.mkto_custom_activities
      });

      toast.success(intl.formatMessage({ id: "SETTINGS.TOAST.SUCCESS" }), toastSettings("success"));
    } catch (err: any) {
      toast.error(`${intl.formatMessage({ id: "SETTINGS.TOAST.ERROR" })}: ${err.message}`, toastSettings("error"));
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setMktoDataFetchStatus(undefined);

    Promise.all([
      axios.get("/status/data"),
      axios.get("/marketo/settings/marketo_activity_types"),
      axios.get("/configs/insentric-mkto")
    ])
      .then((response) => {
        const [status, activityTypes, initialValues] = response;
        if (status?.data?.mkto?.lead_fields.last_successful_fetch !== "") {
          setMktoDataFetchStatus(true);
        } else {
          setMktoDataFetchStatus(false);
        }

        setActivityTypes(activityTypes.data);

        setInitialValues({
          mkto_custom_activities: initialValues.data.custom_activity_type_ids || [],
          mkto_excluded_activities: initialValues.data.excluded_activity_type_ids || []
        });
      })
      .catch((error) => {
        console.error("Error fetching initial values:", error);
      });
  }, []);

  const emptySchema = Yup.object().shape({});

  const fields = [
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.CUSTOMACTIVITYTYPES" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.CUSTOMACTIVITYTYPES.DESC"
      }),
      id: "mkto_custom_activities",
      optional: false,
      options: activityTypes?.custom,
      fieldComponent: (props: any) => (
        <MultiSelectDropdown {...props} placeholder={intl.formatMessage({ id: "FORM.SELECT" })} />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.EXCLUDEDACTIVITYTYPES" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.EXCLUDEDACTIVITYTYPES.DESC"
      }),
      id: "mkto_excluded_activities",
      optional: false,
      options: activityTypes?.default,
      fieldComponent: (props: any) => (
        <MultiSelectDropdown {...props} placeholder={intl.formatMessage({ id: "FORM.SELECT" })} />
      )
    }
  ];

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.MARKETO"
          level1link="/app/home"
          level2name="SETTINGS.MKTO.ACTIVITYTYPES"
          instructions="SETTINGS.MKTO.ACTIVITYTYPES.INSTRUCTIONS"
        />
      </div>
      <div className="card px-3 mb-xl-3">
        {mktoDataFetchStatus === undefined ? (
          <div>
            <InsSpinner />
          </div>
        ) : mktoDataFetchStatus ? (
          initialValues ? (
            <div className="mt-10 mb-10">
              <Container fluid>
                <div>
                  <Form
                    fields={fields}
                    initialValues={initialValues}
                    validationSchema={emptySchema}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </Container>
            </div>
          ) : (
            <div>
              <InsSpinner />
            </div>
          )
        ) : (
          <div className="mt-10 mb-10">
            <Container>
              <div className="d-flex justify-content-center">
                <h3>
                  {intl.formatMessage({
                    id: "SETTINGS.MKTO.GENERAL.DATAFETCHING.WAITMESSAGE"
                  })}
                </h3>
              </div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
