import PropTypes from "prop-types";
import Svg from "./Svg";
import cx from "classnames";

const Button = ({ isSelected, onClick, children }: { isSelected: any; onClick: any; children: any }) => (
  <button type="button" onClick={onClick} className={cx("tz-btn", { "tz-btnIsSelected": isSelected })}>
    <div className="tz-btnContent">
      <div className="tz-btnLabel">{children}</div>
      <div className="tz-btnIconAfter">
        <Svg style={{ marginRight: -6 }}>
          <path
            d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </Svg>
      </div>
    </div>
  </button>
);

Button.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default Button;
