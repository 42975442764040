/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import QueryBuilderSetup from "./components/QueryBuilder/QueryBuilderSetup";
import { ObjectSetup } from "../../helpers/components/object-setup/ObjectSetup";


const SFDCSetupWrapper: FC = () => {
  return (
    <>
      <div className="row gy-5 gx-xl-8">
        <div className="col-12">
          <Routes>
            <Route
              path="/leads-setup"
              element={
                <ObjectSetup platform = "SFDC" object="LEAD" className="mb-xl-3 pt-10" />
              }
            />
            <Route
              path="/contacts-setup"
              element={
                <ObjectSetup platform = "SFDC" object="CONTACT" className="mb-xl-3 pt-10" />
              }
            />
            <Route
              path="/accounts-setup"
              element={
                <ObjectSetup platform = "SFDC" object="ACCOUNT" className="mb-xl-3 pt-10" />
              }
            />
            <Route
              path="/campaigns-setup"
              element={
                <ObjectSetup platform = "SFDC" object="CAMPAIGN" className="mb-xl-3 pt-10" />
              }
            />
            <Route
              path="/campaign-members-setup"
              element={
                <ObjectSetup platform = "SFDC" object="CAMPAIGNMEMBER" className="mb-xl-3 pt-10"/>
              }
            />
            <Route
              path="/opportunities-setup"
              element={
                <ObjectSetup platform = "SFDC" object="OPPORTUNITY" className="mb-xl-3 pt-10"/>
              }
            />
            <Route
              path="/activities-setup"
              element={
                <ObjectSetup platform = "SFDC" object="TASK" className="mb-xl-3 pt-10" />
              }
            />
            <Route
              path="/query-builder"
              element={<QueryBuilderSetup className="mb-xl-3 pt-10" />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

export { SFDCSetupWrapper };
