import PageHeader from "../../../layout/components/content/PageHeader";

import { AccountSettingsType, initialValues } from "./core/_models";
import { useEffect, useState } from "react";
import axios from "axios";
import GenSettingsFormWrapper from "./components/GenSettingsFormWrapper";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import { useIntl } from "react-intl";
import { useRBAC } from "../../roles-permissions/core/RBACProvide";

const AccountSettings = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<string>("");
  const [formValues, setFormValues] = useState<AccountSettingsType>(initialValues);
  const [dataFetchStatus, setDataFetchStatus] = useState<boolean | undefined>(undefined);
  const intl = useIntl();
  const { allowedUserButtonClick } = useRBAC();

  const onSubmit = async (values: AccountSettingsType) => {
    if (!allowedUserButtonClick) {
      toast.error(intl.formatMessage({ id: "ERROR.MESSAGE.NOTALLOWED.SUBMIT" }), toastSettings("error"));
      return;
    }

    setIsSaving(true);
    const payload = {
      account_properties: {
        company_id: values?.company_id || "",
        company_name: values?.company_name || "",
        main_timezone: timezone || "",
        fiscal_year: {
          custom: values?.company_fiscal_year_type || false,
          start_month: values?.company_fiscal_year_start_month || 1
        }
      }
    };

    await axios
      .put("/account", payload)
      .then((response) => {
        setIsSaving(false);
        toast.success(
          intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.MESSAGE.SUCCESS" }),
          toastSettings("success")
        );
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  const fetchAccountData = async () => {
    await axios
      .get("/account")
      .then((response) => {
        if (response.data) {
          setFormValues({
            company_id: response.data.company_id || "",
            company_name: response.data.company_name || "",
            company_main_timezone: response.data.main_timezone || "",
            company_fiscal_year_type: response.data.fiscal_year.custom || false,
            company_fiscal_year_start_month: response.data.fiscal_year.start_month || 1
          });
          setTimezone(response.data.main_timezone || "");
        }
        setDataFetchStatus(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAccountData();
  }, []);

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.HEADER.ACCOUNTSETTINGS"
          level1link="/app/home"
          level2name="SIDEBAR.ITEM.ACCOUNTSETTINGS"
          instructions="SETTINGS.ACCOUNT.ACCOUNTSETTINGS.INSTRUCTIONS"
        />
      </div>

      <div className="flex-column-fluid">
        <div className="container-fluid">
          {dataFetchStatus === false && <InsSpinner />}
          {dataFetchStatus === true && (
            <GenSettingsFormWrapper
              formValues={formValues}
              onSubmit={onSubmit}
              isSaving={isSaving}
              timezone={timezone}
              setTimezone={setTimezone}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
