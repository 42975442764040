import React, { useEffect } from 'react';
import "./style/_ins-spinner.css";

interface InsSpinnerProps {
  text?: string;
  opacity?: string; 
}

const InsSpinner: React.FC<InsSpinnerProps> = ({ text, opacity }) => {
  useEffect(() => {
    const overlay = document.getElementById('overlay');
    if (overlay) {
      overlay.id = 'overlay-wash';
    }

    return () => {
      if (overlay) {
        overlay.id = 'overlay';
      }
    };
  }, []);

  return (
    <div className="spinner-container" style={{ opacity }}>
      <div className="loader">
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__ball"></div>
      </div>
      {text && <span className="loaderText">{text}</span>}
    </div>
  );
};

export default InsSpinner;
