/*eslint-disable  filenames/match-exported*/
import * as ack from "./ack.json";
import * as auth from "./auth.json";
import * as common from "./common.json";
import * as footer from "./footer.json";
import * as pagination from "./pagination.json";
import * as insentric_js from "./insentric_js.json";
import * as query_builder from "./query_builder.json";
import * as secrets from "./secrets.json";
import * as sfdc from "./sfdc.json";
import * as sidebar from "./sidebar.json";
import * as users from "./users.json";
import * as home from "./home.json";

import * as account_settings from "./pages/account_settings.json";
import * as roles_and_permissions from "./roles_and_permissions.json";

import * as marketo_activity_types from "./marketo/marketo_activity_types.json";
import * as marketo_fetch_activities from "./marketo/marketo_fetch_activities.json";
import * as marketo_fetch_programs from "./marketo/marketo_fetch_programs.json";
import * as marketo_fields from "./marketo/marketo_fields.json";
import * as marketo_general from "./marketo/marketo_general.json";
import * as marketo_lifecycle_stages from "./marketo/marketo_lifecycle_stages.json";
import * as marketo_link_appender from "./marketo/marketo_link_appender.json";
import * as marketo_prog_status_map from "./marketo/marketo_prog_status_map.json";

import * as sfdc_objects from "./pages/sfdc_objects.json";

const enMessages = {
  ...ack,
  ...auth,
  ...common,
  ...footer,
  ...pagination,
  ...insentric_js,
  ...query_builder,
  ...secrets,
  ...sfdc,
  ...sidebar,
  ...users,
  ...home,
  ...account_settings,
  ...roles_and_permissions,
  ...marketo_activity_types,
  ...marketo_fetch_activities,
  ...marketo_fetch_programs,
  ...marketo_fields,
  ...marketo_general,
  ...marketo_lifecycle_stages,
  ...marketo_link_appender,
  ...marketo_prog_status_map,
  ...sfdc_objects
};

export default enMessages;