import PasswordForm from "./PasswordForm";
import { useAuth } from "../../../auth"; // Assuming the path to the useAuth hook

const PasswordSettings = () => {
  const { currentUser } = useAuth();

  // Check if currentUser is available before trying to access login_method
  if (!currentUser) {
    return <div>Loading...</div>;
  }

  const login_method = currentUser.login_method;

  // If login_method is "google", do not render anything
  if (login_method === "google") {
    return null;
  }

  return (
    <div className="card mb-5 mb-xxl-8">
      <div className="card-header">
        <h2 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-2">{currentUser.first_name}</span>
        </h2>
      </div>
      <div className="card-body">
        <PasswordForm />
      </div>
    </div>
  );
};

export default PasswordSettings;
