import { Form as BootstrapForm } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";
import Select from "react-select";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { ThemeModeComponent } from "../../../../assets/ts/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { handleRefreshClick } from "../helper/helpers";
import styles from "../style/MultiSelectDropdown.module.css";

type MultiSelectDropdownProps = {
  id: string;
  label: string;
  description?: string;
  options: { value: string; label: string }[];
  showRefreshButton?: boolean;
  refreshType?: string;
  classes?: string;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Multi select dropdown field component that uses the Select "react-select" component to render a multi select dropdown input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} label - The label for the field
 * @prop {string} [description] - The description for the field
 * @prop {{ value: string; label: string }[]} options - The options for the field
 * @prop {boolean} [showRefreshButton=false] - Whether to show the refresh button or not
 * @prop {string} [refreshType] - The type of refresh to perform
 * @prop {string} [classes] - Any additional classes to apply to the field
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const MultiSelectDropdown = ({
  id,
  label,
  description,
  options = [],
  showRefreshButton = false,
  refreshType,
  classes,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: MultiSelectDropdownProps) => {
  const [, meta, helpers] = useField(id);
  const intl = useIntl();
  const { value } = meta;
  const { setValue } = helpers;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [themeMode, setThemeMode] = useState(ThemeModeComponent.getMode());

  const handleChange = (newValue: any) => {
    setValue(newValue ? newValue.map((option: { value: string; label: string }) => option.value) : []);
  };

  useEffect(() => {
    setThemeMode((prevState) => ThemeModeComponent.getMode());
  }, [ThemeModeComponent.getMode()]);

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>}
      <div className="d-flex align-items-center flex-help">
        <Select
          id={id}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
          options={options}
          isMulti
          value={options.filter((option) => value && value.includes(option.value))}
          onChange={(newValue) => handleChange(newValue)}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
          className={classes ? classes : ""}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              neutral80: themeMode === "dark" ? "#ffffff" : "#333333"
            }
          })}
          {...props}
        />
        {showRefreshButton && (
          <FontAwesomeIcon
            icon={faSync}
            onClick={() => handleRefreshClick(refreshType || "", setIsRefreshing, intl)}
            className={isRefreshing ? `${styles.refreshBtnIcon} spin` : `${styles.refreshBtnIcon}`}
          />
        )}
      </div>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default MultiSelectDropdown;
