export const baseStyle: any = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#2E4EA2",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

export const focusedStyle: any = {
  borderColor: "#2E4EA2"
};

export const acceptStyle: any = {
  borderColor: "#47be7d"
};

export const rejectStyle: any = {
  borderColor: "#d9214e"
};

export const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: 20,

};

export const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  marginBottom: 8,
  marginRight: 8,
  maxWidth: "auto",
  maxHeight: 300,
  height: "100%",
  width: "100%",
  padding: 4,
  boxSizing: 'border-box',
};

