import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { KTIcon } from "../../../../helpers";
import { canvasPreview } from "../core/_helpers";

interface CompanyImageCropperProps {
  imgSrc: { preview: string };
  handleCroppedImage: (dataURL: string) => void;
}

const CompanyImageCropper: React.FC<CompanyImageCropperProps> = ({ imgSrc, handleCroppedImage }) => {
  const [crop, setCrop] = useState<Crop | undefined>();
  const [aspect, setAspect] = useState<any>(undefined);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const intl = useIntl();

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setCrop({
      unit: "%",
      width: 50,
      height: 50,
      x: 25,
      y: 25
    });
  };

  const getCroppedImage = () => {
    if (!completedCrop || !imgRef.current || !previewCanvasRef.current) {
      return;
    }

    const dataURL = previewCanvasRef.current.toDataURL("image/png");
    handleCroppedImage(dataURL);
    console.log("dataURL", dataURL);
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
  }, [completedCrop]);

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center m-6 gap-5"
        style={{ minHeight: 150 }}
      >
        <ReactCrop
          crop={crop}
          keepSelection
          aspect={aspect}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
        >
          {imgSrc?.preview && (
            <img
              ref={imgRef}
              src={imgSrc.preview}
              alt={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.UPLOADEDIMAGE", defaultMessage: "Uploaded image" })}
              onLoad={onImageLoad}
            />
          )}
        </ReactCrop>
      </div>

      {!!completedCrop && (
        <div className="d-flex justify-content-center mb-6">
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height
            }}
          />
        </div>
      )}

      <div className="d-flex justify-content-center">
        <button
          type="reset"
          className="btn btn-light me-3 btn-sm rounded-pill"
          data-bs-dismiss="modal"
        >
          <KTIcon iconName="exit-left" className="fs-4" />
          <span>{intl.formatMessage({ id: "BUTTON.CANCEL", defaultMessage: "Cancel" })}</span>
        </button>

        <button
          type="button"
          className="btn btn-primary btn-sm rounded-pill"
          onClick={getCroppedImage}
          data-bs-dismiss="modal"
        >
          <KTIcon iconName="design-frame" className="fs-4" />
          <span>{intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.CROPIMAGE", defaultMessage: "Crop Image" })}</span>
        </button>
      </div>
    </>
  );
};

CompanyImageCropper.propTypes = {
  imgSrc: PropTypes.shape({
    preview: PropTypes.string.isRequired
  }).isRequired,
  handleCroppedImage: PropTypes.func.isRequired
};

export default CompanyImageCropper;
