import {
  DateField,
  MultiSelectDropdownWithInput
} from "../../../../../layout/components/form";
import { useIntl } from "react-intl";

const FormFieldStartDate = (props: any) => {
  const intl = useIntl();
  return (
    <div className="m-3 flex-fill">
      <div className="d-flex justify-content-between align-items-start flex-column flex-sm-row">
        <div>
          <span className="fs-5 fw-bold" id="start-date-label">
            {intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.STARTDATE" })}
          </span>
          <div className="text-muted mb-3" id="start-date-description">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.STARTDATE.DESC"
            })}
          </div>
        </div>
        <div>
          <DateField
            id="mkto_qs_appender_start_date"
            label={intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.STARTDATE" })}
            hideLabel={true}
            description=""
            ariaLabelledBy="start-date-label"
            ariaDescribedBy="start-date-description"
            hideDescription={true}
            classes={
              props.errors.mkto_qs_appender_start_date &&
              props.touched.mkto_qs_appender_start_date
                ? "border-danger"
                : ""
            }
          />
          {!!props.errors.mkto_qs_appender_start_date &&
          props.touched.mkto_qs_appender_start_date ? (
            <p className="text-danger">
              {intl.formatMessage({
                id: props.errors.mkto_qs_appender_start_date
              })}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormFieldStartDate;
