export type AccountSettingsType = {
  company_id: string;
  company_name: string;
  company_main_timezone: string;
  company_fiscal_year_type: boolean;
  company_fiscal_year_start_month: number;
};

export const initialValues: AccountSettingsType = {
  company_id: "",
  company_name: "",
  company_main_timezone: "",
  company_fiscal_year_type: false,
  company_fiscal_year_start_month: 1
};

export const fiscalYearStartMonthValues = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" }
];
