import { useIntl } from "react-intl";
import { KTIcon } from "../..";

const ObjectSetupCheckAll = (props: any) => {
  const intl = useIntl();

  return (
    <button
      id="checkAll"
      type="button"
      onClick={props.handleCheckAll}
      className="btn btn-secondary me-2"
    >
      <KTIcon iconName="check-square" className="fs-1" />
      <span className="indicator-label d-none d-xl-inline-block">
        {intl.formatMessage({ id: "SETTINGS.OBJECTFIELDS.CHECKALL" })}
      </span>
    </button>
  );
};

export default ObjectSetupCheckAll;
