import { useIntl } from "react-intl";
import { KTIcon } from "../..";

const ObjectSetupUnCheckAll = (props: any) => {
  const intl = useIntl();
  const buttonText = (
    <>
      <KTIcon
        iconName="minus-square"
        className="fs-1"
      />
      <span className="indicator-label d-none d-xl-inline-block">
        {intl.formatMessage({ id: "SETTINGS.OBJECTFIELDS.UNCHECKALL" })}
      </span>
    </>
  );

  return (
    <button
      id="unCheckAll"
      type="button"
      onClick={props.handleUnCheckAll}
      className="btn btn-secondary me-2"
      style={{ zIndex: 1000 }}
    >
      {buttonText}
    </button>
  );
};

export default ObjectSetupUnCheckAll;
