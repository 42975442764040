import axios from "axios";
import { toast } from "react-toastify";
import { toastSettings } from "../../toast/Toast";

/**
 *
 *
 * @param url -
 * @param setIsRefreshing -
 * @param intl -
 * @param body -
 */

type fetchAndHandleProps = {
  url: string;
  setIsRefreshing: (value: boolean) => void;
  intl: any;
  body?: any;
};

export const fetchAndHandle = async ({ url, setIsRefreshing, intl, body }: fetchAndHandleProps) => {
  try {
    await axios.post(url, body);
    toast.success(intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.SUCCESS" }), toastSettings("success"));
    toast.success(intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.SUCCESS" }), toastSettings("success"));
  } catch (err: any) {
    toast.error(
      `${intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.ERROR"
      })}: ${err.message}`,
      toastSettings("error")
    );
    toast.error(
      `${intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.ERROR"
      })}: ${err.message}`,
      toastSettings("error")
    );
  } finally {
    setIsRefreshing(false); // Ensure the spinner is stopped
  }
};

/**
 * Handles the refresh click event for the refresh button in the component.
 *
 * @param refreshType - The type of refresh to perform
 * @param setIsRefreshing - The function to set the isRefreshing state in the component to true or false to show or hide the spinner
 * @param intl - The react-intl object to get the translations for the toast messages displayed.
 */

export const handleRefreshClick = async (
  refreshType: string,
  setIsRefreshing: (value: boolean) => void,
  intl: any
): Promise<void> => {
  setIsRefreshing(true); // Start the spinner

  try {
    switch (refreshType) {
      case "smartlist":
        await fetchAndHandle({
          url: "/marketo/fetch/assets",
          setIsRefreshing,
          intl,
          body: { assetNames: ["smart_lists"] }
        });
        break;
      case "lead":
        // Refresh logic for lead
        break;
      case "fields":
        // Refresh logic for fields
        break;
      case "programs":
        await fetchAndHandle({ url: "/marketo/fetch/programs", setIsRefreshing, intl });
        break;
      default:
        // No action needed for default case
        break;
    }
  } catch (error) {
    console.error("Error during refresh: ", error);
    // Optionally handle the error here instead
  } finally {
    setIsRefreshing(false); // Stop the spinner
  }
};

/**
 * Returns an error if the prop is not provided when showRefreshButton is true in the component, otherwise returns null.
 *
 * @param props -
 * @param propsName -
 * @param componentName -
 * @returns Error | null
 */

export const requireRefreshType = (props: any, propName: string, componentName: string): Error | null => {
  if (props.showRefreshButton && !props[propName]) {
    return new Error(`${propName} is required when showRefreshButton is true in ${componentName}`);
  }
  return null;
};
