import React from 'react';
import { useIntl } from 'react-intl';
import { KTIcon } from "../..";

const ObjectSetupSearch = (props:any) => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center position-relative">
      <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-6" />
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="form-control form-control-solid mw-350px w-100 ps-14"
        placeholder={intl.formatMessage({ id: 'SETTINGS.OBJECTFIELDS.SEARCH', defaultMessage: 'Search field' })}
        onChange={(e) => props.handleSearch(e)}
      />
    </div>
  );
};

export default ObjectSetupSearch;
