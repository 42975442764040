/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import axios from "axios";
import { useRBAC } from "../../../../pages/roles-permissions/core/RBACProvide";

const SidebarMenuMain: React.FC = () => {
  const intl = useIntl();
  const { allowedUserViewPage } = useRBAC();

  const [mktoDataFetchStatus, setMktoDataFetchStatus] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/status/data");
        if (
          response?.data?.mkto?.lead_fields.last_successful_fetch !== ""
          /* &&
          response?.data?.mkto?.object_fields.last_successful_fetch !== "" */
        ) {
          setMktoDataFetchStatus(true);
        } else {
          setMktoDataFetchStatus(false);
        }
      } catch (error) {
        console.error("Error fetching initial values:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <SidebarMenuItem
        to="/app/home"
        title={intl.formatMessage({ id: "SIDEBAR.HOME" })}
        hasBullet={false}
        icon="home"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "SIDEBAR.HEADER.USERMGMT" })}
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to="/app/user-management/users"
        title={intl.formatMessage({ id: "SIDEBAR.ITEM.MANAGEUSERS" })}
        hasBullet={false}
        icon="profile-user"
      />
      {allowedUserViewPage && (
        <SidebarMenuItem
          to="/app/roles-and-permissions"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.MANAGEROLES" })}
          hasBullet={false}
          icon="security-user"
        />
      )}

      <SidebarMenuItem
        to="app/account-settings/account-settings"
        title={intl.formatMessage({
          id: "SIDEBAR.ITEM.ACCOUNTSETTINGS"
        })}
        hasBullet={false}
        icon="setting-4"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "SIDEBAR.HEADER.CONFIGURATION" })}
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/app/configs/secrets"
        title={intl.formatMessage({ id: "SIDEBAR.SUBHEAD.AUTHKEYS" })}
        icon="key"
      />
      <SidebarMenuItemWithSub
        to="/app/configs/marketo_settings"
        title={intl.formatMessage({ id: "SIDEBAR.SUBHEAD.MARKETO" })}
        icon="setting-2"
      >
        {mktoDataFetchStatus && (
          <>
            <SidebarMenuItem
              to="/app/configs/marketo_settings"
              title={intl.formatMessage({
                id: "SIDEBAR.ITEM.MKTOGENERALSETTINGS"
              })}
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/app/configs/marketo_activity_types"
              title={intl.formatMessage({ id: "SIDEBAR.ITEM.ACTIVITYTYPES" })}
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/app/configs/marketo_lifecycle_stages"
              title={intl.formatMessage({ id: "SIDEBAR.ITEM.MKTOLLSTAGES" })}
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/app/configs/marketo_progression_status_map"
              title={intl.formatMessage({ id: "SIDEBAR.ITEM.PROGSTATUSMAP" })}
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/app/configs/marketo_qs_appender_settings"
              title={intl.formatMessage({ id: "SIDEBAR.ITEM.QSAPPEND" })}
              hasBullet={true}
            />
          </>
        )}
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/app/marketo-configs/leads-setup"
        title={intl.formatMessage({ id: "SIDEBAR.SUBHEAD.MKTOFIELDS" })}
        icon="data"
      >
        <SidebarMenuItem
          to="/app/marketo-config/leads-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.MKTOLEADS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/marketo-config/companies-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.MKTOCOMPANIES" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/marketo-config/opportunities-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.MKTOOPPORTUNITIES" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/marketo-config/program-members-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.MKTOPROGRAMMEMBERS" })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/app/configs/salesforce_settings"
        title={intl.formatMessage({ id: "SIDEBAR.SUBHEAD.SFDC" })}
        icon="cloud"
      >
        <SidebarMenuItem
          to="/app/configs/salesforce_settings"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCGENERALSETTINGS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/query-builder"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.QUERYBUILDER" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/leads-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCLEADS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/contacts-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCCONTACTS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/accounts-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCACCOUNTS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/campaigns-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCCAMPAIGNS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/campaign-members-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCCAMPAIGNMEMBERS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/opportunities-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCOPPORTUNITIES" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/salesforce-config/activities-setup"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.SFDCTASKS" })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to="app/account-settings/insentric-js-configurator"
        title={intl.formatMessage({ id: "SIDEBAR.SUBHEAD.ACQTRACKER" })}
        icon="abstract-31"
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "SIDEBAR.HEADER.UTILITIES" })}
          </span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to="/fetch"
        title={intl.formatMessage({ id: "SIDEBAR.SUBHEAD.UPDATEMKTO" })}
        icon="technology-4"
      >
        <SidebarMenuItem
          to="/app/fetch/marketo_programs"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.MKTOPROGRAMS" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/app/fetch/marketo_activities"
          title={intl.formatMessage({ id: "SIDEBAR.ITEM.MKTOACTIVITIES" })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  );
};

export { SidebarMenuMain };
