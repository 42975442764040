import PageHeader from "../../../layout/components/content/PageHeader";
import PasswordSettings from "./components/PasswordSettings";
import ProfileSettings from "./components/ProfileSettings";

const UserProfile = () => {
  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7 pl-3">
        <PageHeader
          level1name="SIDEBAR.HOME"
          level1link="/app/home"
          level2name="USER.MYPROFILE.SETTINGS"
          instructions="USER.MYPROFILE.SETTINGS.INSTRUCTIONS"
        />
      </div>

      <div className="flex-column-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-5">
              <ProfileSettings />
            </div>

            <div className="col-12 col-lg-7">
              <PasswordSettings />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
