import { createPortal } from "react-dom";
import axios from "axios";
import { Modal, Alert } from "react-bootstrap";
import { KTIcon } from "../../../helpers";
import { useIntl } from "react-intl";
import { MultiSelectDropdownWithInput } from "../form";
import Form from "../form/Form";
import { useEffect, useState, useMemo } from "react";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";
import { useRBAC } from "../../../pages/roles-permissions/core/RBACProvide";
import { toast } from "react-toastify";
import { toastSettings } from "../toast/Toast";

type Props = {
  show: boolean;
  handleClose: () => void;
  channel: {
    id: string;
    name: string;
    applicableProgramType: string;
    progressionStatuses: {
      name: string;
    }[];
  };
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ProgressionStatusMapModal = ({ show, handleClose, channel }: Props) => {
  const { allowedUserButtonClick } = useRBAC();

  const [initialValues, setInitialValues] = useState<{ [key: string]: { value: string; label: string }[] } | undefined>(
    undefined
  );
  const [alert, setAlert] = useState<{ variant: string; message: string } | undefined>(undefined);

  const intl = useIntl();

  const progressionStatuses = useMemo(
    () => channel?.progressionStatuses?.map((status) => ({ value: status.name, label: status.name })) || [],
    [channel]
  );

  const fields = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.QUEUED" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.QUEUED.DESC" }),
        id: "1_queued",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.NONMAILABLE" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.NONMAILABLE.DESC" }),
        id: "2_non_mailable",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
            {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.SENT" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.SENT.DESC" }),
        id: "3_sent",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.DELIVERED" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.DELIVERED.DESC" }),
        id: "4_delivered",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.OPEN" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.OPEN.DESC" }),
        id: "5_open",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.CLICKED" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.CLICKED.DESC" }),
        id: "6_clicked",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.CONVERTED" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.CONVERTED.DESC" }),
        id: "7_converted",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.BOUNCED" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.BOUNCED.DESC" }),
        id: "8_bounced",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.EXCLUDED" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.EXCLUDED.DESC" }),
        id: "9_excluded",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      },
      {
        label: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.UNSUBSCRIBED" }),
        description: intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.UNSUBSCRIBED.DESC" }),
        id: "10_unsubscribed",
        options: progressionStatuses,
        fieldComponent: MultiSelectDropdownWithInput
      }
    ],
    [intl, progressionStatuses]
  );

  const handleSubmit = async (
    values: {
      [key: string]: {
        value: string;
        label: string;
      }[];
    },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (!allowedUserButtonClick) {
      toast.error(intl.formatMessage({ id: "ERROR.MESSAGE.NOTALLOWED.SUBMIT" }), toastSettings("error"));
      return;
    }
    const progressionStatusMap = {
      [channel.id]: Object.keys(values).reduce((acc, key) => {
        acc[key] = values[key].map((value) => value.value);
        return acc;
      }, {} as { [key: string]: string[] })
    };

    setSubmitting(true);

    try {
      await axios.post("/marketo/settings/marketo_progression_status_mapping", {
        progression_status_to_email_map: progressionStatusMap
      });

      setAlert({
        variant: "success",
        message: "Progression Status Map updated successfully!"
      });
    } catch (err) {
      setAlert({
        variant: "danger",
        message: `Error updating Progression Status Map: ${(err as Error).message}`
      });
    }
  };

  useEffect(() => {
    if (!channel) {
      return;
    }

    axios.get("/configs/insentric-mkto").then(({ data }) => {
      const progressionStatusMap = data.progression_status_to_email_map?.[channel.id] || {};

      const mappings = {
        ...Object.keys(progressionStatusMap).reduce((acc, status) => {
          acc[status] = progressionStatusMap[status].map((value: string) => ({ value, label: value }));
          return acc;
        }, {} as { [key: string]: { value: string; label: string }[] })
      };

      for (const field of fields) {
        if (!mappings[field.id]) {
          mappings[field.id] = [];
        }
      }

      setInitialValues(mappings);
    });
  }, [show, channel, fields]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-labelledby="progression-status-modal-title"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
      onShow={() => setInitialValues(undefined)}
      backdrop="static"
      aria-modal="true"
    >
      <div className="modal-header d-flex justify-content-between">
        <h2 id="progression-status-modal-title">
          {intl.formatMessage({ id: "SETTINGS.MKTO.PROGSTATUSMAP.MODALTITLE" })} {channel?.name}
        </h2>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
          aria-label="Close"
        >
          <KTIcon className="fs-1" iconName="cross" />
        </button>
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        {initialValues ? (
          <Form
            fields={fields}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            validationSchema={undefined}
          />
        ) : (
          <InsSpinner />
        )}
      </div>

      <Alert
        variant={alert?.variant}
        show={!!alert}
        onClose={() => setAlert(undefined)}
        dismissible
        style={{ position: "absolute", bottom: 0, right: 0, margin: 80 }}
        aria-live="polite"
      >
        {alert?.message}
      </Alert>
    </Modal>,
    modalsRoot
  );
};

export { ProgressionStatusMapModal };
