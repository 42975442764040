import React, { useCallback, useEffect, useState } from "react";
import type { JsonGroup, Config, ImmutableTree, BuilderProps } from "@react-awesome-query-builder/bootstrap";
import { Utils as QbUtils, Query, Builder } from "@react-awesome-query-builder/bootstrap";
import "../../style/style.scss";

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };

const QueryBuilder: React.FC<{ config: Config, SOQLFormat: any }> = ({ config, SOQLFormat } ) => {
  const [soqlFormat, setSoqlFormat] = useState<string>();
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config
  });

  const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    setState((prevState) => ({ ...prevState, tree: immutableTree, config: config }));

    const jsonTree = QbUtils.getTree(immutableTree);
  }, []);

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container" style={{ padding: "10px" }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  // Update the SOQL state (soqlFormat) every time the 'state' useState is updated
  // meaning that a field was added/removed/updated in the query builder
  useEffect(() => {
    let temp: string | undefined = QbUtils.sqlFormat(state.tree, state.config);
    if (temp !== undefined) {
      // Check and update 'IS NOT NULL' and 'IS NULL' to '!= null' and '== null' in the case of SOQL
      temp = temp.replaceAll("IS NOT NULL", "!= null").replaceAll("IS NULL", "== null");

      // Check and update DateTime values (remove quotes)
      const regexpDateTime = /'(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2}).(\d{3})'/gm;
      const iteratorDateTime: any = temp.matchAll(regexpDateTime);
      const matchesDateTime = [...iteratorDateTime];

      for (const match of matchesDateTime) {
        temp = temp.replace(match[0], match[0].slice(1, -1));
      }

      // Check and update Date values (remove quotes)
      const regexpDate = /'(\d{4})-(\d{2})-(\d{2})'/gm;
      const iteratorDate: any = temp.matchAll(regexpDate);
      const matchesDate = [...iteratorDate];

      for (const match of matchesDate) {
        temp = temp.replace(match[0], match[0].slice(1, -1));
      }
    }
    // Update SOQL state
    setSoqlFormat(temp);
    SOQLFormat(temp);

  }, [SOQLFormat, state]);

  return (
    <div className="w-100">
      <Query {...config} value={state.tree} onChange={onChange} renderBuilder={renderBuilder}/>
      <div className="query-builder-result d-none">
        <div>
          SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(state.tree, state.config))}</pre>
        </div>
        <div>
          SOQL where: <pre>{soqlFormat}</pre>
        </div>
      </div>
    </div>
  );
};
export default QueryBuilder;
