import { Navigate } from "react-router-dom";
import { useVerification } from "./core/VerificationProvider";
import SetPassword from "./set-password/SetPassword";
import VerifyYourEmail from "./verify-email/VerifyYourEmail";
import ExpiredVerification from "./errors/ExpiredVerification";
import InvalidVerificationCode from "./errors/InvalidVerificationCode";

const UserVerification = () => {
  const verification = useVerification();

  let content = null;

  console.log("Verification module obj : ", verification);

  // If verification mode is not valid, redirect to auth page
  if (verification.mode !== "verifyEmail" && verification.mode !== "resetPassword") {
    content = <Navigate to="/auth" />;
  }

  // If verification mode is valid, check if the verification code is expired
  // If expired, show expired verification page
  if (
    (verification.mode === "verifyEmail" || verification.mode === "resetPassword") &&
    verification.isActionCodeExpired === true
  ) {
    content = <ExpiredVerification />;
  }

  // If verification mode is valid but the verification code is NOT verified
  // Show invalid verification code page
  if (
    (verification.mode === "verifyEmail" || verification.mode === "resetPassword") &&
    verification.isActionCodeVerified === false &&
    verification.isActionCodeExpired === false
  ) {
    content = <InvalidVerificationCode />;
  }

  // If verification mode is valid and it's "verifyEmail" and verification code is not expired
  // Show the verify email page to the user
  if (
    verification.mode === "verifyEmail" &&
    verification.operation === "VERIFY_EMAIL" &&
    verification.isActionCodeExpired === false &&
    verification.isActionCodeVerified === true
  ) {
    content = <VerifyYourEmail />;
  }

  // If verification mode is valid andit's "resetPassword" and verification code is not expired
  // Show the set password page to the user
  if (
    verification.mode === "resetPassword" &&
    verification.operation === "PASSWORD_RESET" &&
    verification.isActionCodeExpired === false &&
    verification.isActionCodeVerified === true
  ) {
    content = <SetPassword />;
  }

  if (content === null) {
    content = <Navigate to="/auth" />;
  }

  return <>{!verification.isFetching && content}</>;
};

export default UserVerification;
