import React from 'react';
import icons from '../icons-config/icons';
import { getLayoutFromLocalStorage } from '../../layout/core';

type Props = {
  className?: string;
  iconType?: 'duotone' | 'solid' | 'outline';
  iconName: string;
}

const KTIcon: React.FC<Props> = ({ className = '', iconType, iconName }) => {
  const layoutConfig = getLayoutFromLocalStorage();
  const finalIconType = iconType ?? layoutConfig?.main?.iconType ?? 'solid';

  return (
    <i aria-hidden={true} className={`ki-${finalIconType} ki-${iconName}${className && ' ' + className}`}>
      {finalIconType === 'duotone' &&
        Array.from({ length: icons[iconName] }).map((_, index) => (
          <span
            key={`${finalIconType}-${iconName}-${className}-path-${index + 1}`}
            className={`path${index + 1}`}
          ></span>
        ))}
    </i>
  );
};

export { KTIcon };
