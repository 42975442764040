import { useIntl } from "react-intl";
import { SingleSelectDropdown } from "../../../../layout/components/form";
import { fiscalYearStartMonthValues } from "../core/_models";
import { useRBAC } from "../../../roles-permissions/core/RBACProvide";

const GenSettingsFiscalYearStartMonth = ({ value }: { value: any }) => {
  const intl = useIntl();
  const { allowedUserInputEdit } = useRBAC();

  return (
    <div className="row">
      <div className="col-lg-4">
        <span className="fw-bold fs-4 text-muted" id="company_fiscal_year_start_month-label">
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.FISCALYEARSTARTMONTH"
          })}
        </span>
      </div>
      <div className="col-lg-8">
        <SingleSelectDropdown
          id="company_fiscal_year_start_month"
          label=""
          description=""
          selectedOption="1"
          options={fiscalYearStartMonthValues}
          showRefreshButton={false}
          hideLabel={true}
          hideDescription={true}
          refreshType=""
          ariaLabelledBy="company_fiscal_year_start_month-label"
          ariaDescribedBy=""
        />
      </div>
    </div>
  );
};

export default GenSettingsFiscalYearStartMonth;
