import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import { FC } from "react";
import "./_style.css";
import { useIntl } from "react-intl";

type CopyToClipboardIconProps = {
  handleCopyPassword: () => void;
  copied: boolean;
};

const CopyToClipboardIcon: FC<CopyToClipboardIconProps> = ({ handleCopyPassword, copied }) => {
  const intl = useIntl();

  return (
    <div
      className="profile-copy-to-clipboard-icon"
      onClick={handleCopyPassword}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleCopyPassword();
        }
      }}
      role="button"
      tabIndex={0}
      aria-pressed={copied}
      aria-label={
        copied
          ? intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.PASSWORDCOPIED" })
          : intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.COPYPASSWORD" })
      }
    >
      {copied ? (
        <KTIcon iconName="check-square" iconType="outline" className="fs-1 text-success" />
      ) : (
        <div data-tooltip-id="copyToClipboard">
          <KTIcon iconName="copy" iconType="outline" className="iconBtn fs-1" />
          <Tooltip
            id="copyToClipboard"
            key="copyToClipboard"
            content={intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.COPYPASSWORD" })}
            place="top"
            variant="dark"
            className="zindex-tooltip"
          />
        </div>
      )}
    </div>
  );
};

export { CopyToClipboardIcon };
