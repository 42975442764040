import { createContext, FC, useContext, useState } from "react";
import { WithChildren } from "../../../helpers";
import { IdForUpdate, initialRoleView, rolesAndPermissionsContextProps } from "./_models";
import { examplePermissionsData, exampleRolesData } from "./__sampleData";

const RolesAndPermissionsContext = createContext<rolesAndPermissionsContextProps>(initialRoleView);

const RolesAndPermissionsProvider: FC<WithChildren> = ({ children }) => {
  const [roleIdForUpdate, setRoleIdForUpdate] = useState<IdForUpdate>(initialRoleView.roleIdForUpdate);

  const [roles, setRoles] = useState(exampleRolesData);
  const [permissions, setPermissions] = useState(examplePermissionsData);
  const [users, setUsers] = useState([]);

  return (
    <RolesAndPermissionsContext.Provider
      value={{
        roles,
        permissions,
        users: [],
        selected: [],
        roleIdForUpdate,
        setRoleIdForUpdate
      }}
    >
      {children}
    </RolesAndPermissionsContext.Provider>
  );
};

const useRolesView = () => useContext(RolesAndPermissionsContext);

export { RolesAndPermissionsProvider, useRolesView };
