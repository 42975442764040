import { useIntl } from "react-intl";

const StepController = ({ isInitialAndConversion }: { isInitialAndConversion: boolean | undefined }) => {
  const intl = useIntl();

  return (
    <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-250px w-xxl-350px me-9">
      <nav className="stepper-nav mb-5" aria-label="Progress">
        <div className="stepper-item current" data-kt-stepper-element="nav" aria-current="step">
          <div className="stepper-wrapper">
            <div className="stepper-icon w-40px h-40px" aria-hidden="true">
              <i className="stepper-check fas fa-check"></i>
              <span className="stepper-number">1</span>
            </div>
            <div className="stepper-label">
              <h3 className="stepper-title">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1"
                })}
              </h3>
              <div className="stepper-desc fw-semibold">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DESCRIPTION"
                })}
              </div>
            </div>
          </div>
          <div className="stepper-line h-40px" aria-hidden="true"></div>
        </div>

        {isInitialAndConversion && (
          <div className="stepper-item" data-kt-stepper-element="nav">
            <div className="stepper-wrapper">
              <div className="stepper-icon w-40px h-40px" aria-hidden="true">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">2</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">
                  {intl.formatMessage({
                    id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP2"
                  })}
                </h3>
                <div className="stepper-desc fw-semibold">
                  {intl.formatMessage({
                    id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP2.DESCRIPTION"
                  })}
                </div>
              </div>
            </div>
            <div className="stepper-line h-40px" aria-hidden="true"></div>
          </div>
        )}

        <div className="stepper-item" data-kt-stepper-element="nav">
          <div className="stepper-wrapper">
            <div className="stepper-icon w-40px h-40px" aria-hidden="true">
              <i className="stepper-check fas fa-check"></i>
              <span className="stepper-number">{isInitialAndConversion ? "3" : "2"}</span>
            </div>
            <div className="stepper-label">
              <h3 className="stepper-title">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP3"
                })}
              </h3>
              <div className="stepper-desc fw-semibold">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP3.DESCRIPTION"
                })}
              </div>
            </div>
          </div>
          <div className="stepper-line h-40px" aria-hidden="true"></div>
        </div>

        <div className="stepper-item" data-kt-stepper-element="nav">
          <div className="stepper-wrapper">
            <div className="stepper-icon w-40px h-40px" aria-hidden="true">
              <i className="stepper-check fas fa-check"></i>
              <span className="stepper-number">{isInitialAndConversion ? "4" : "3"}</span>
            </div>
            <div className="stepper-label">
              <h3 className="stepper-title">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP4"
                })}
              </h3>
              <div className="stepper-desc fw-semibold">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP4.DESCRIPTION"
                })}
              </div>
            </div>
          </div>
          <div className="stepper-line h-40px" aria-hidden="true"></div>
        </div>

        <div className="stepper-item" data-kt-stepper-element="nav">
          <div className="stepper-wrapper">
            <div className="stepper-icon w-40px h-40px" aria-hidden="true">
              <i className="stepper-check fas fa-check"></i>
              <span className="stepper-number">{isInitialAndConversion ? "5" : "4"}</span>
            </div>
            <div className="stepper-label">
              <h3 className="stepper-title">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP5"
                })}
              </h3>
              <div className="stepper-desc fw-semibold">
                {intl.formatMessage({
                  id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP5.DESCRIPTION"
                })}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StepController;
