import { useIntl } from "react-intl";
import PageHeader from "../../../layout/components/content/PageHeader";
import { KTIcon } from "../../../helpers";
import InsentricJSModal from "./edit-modal/InsentricJSModal";
import InsentricJSFiles from "./components/InsentricJSFiles";
import "./style/style.scss";
import {
  InsJSFilesProvider,
  useInsJSFilesRequest
} from "./core/InsJSFilesProvider";

const InsentricJsPage = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useInsJSFilesRequest();
  const openNewFileModal = () => {
    setItemIdForUpdate(null);
  };
  const intl = useIntl();

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.HEADER.ACCOUNTSETTINGS"
          level1link="/app/home"
          level2name="SIDEBAR.ITEM.INSENTRIJSCONFIGURATOR"
          instructions="SETTINGS.ACCOUNT.INSENTRICJS.INSTRUCTIONS"
        />
      </div>

      <div className={`card`}>
        <div className="card-header py-6">
          <div className="row">
            <div className="col-lg-12">
              <h2>
                {intl.formatMessage({
                  id: "SETTINGS.ACCOUNT.INSENTRICJS.TITLE"
                })}
              </h2>
            </div>
          </div>
        </div>
        <div className="row px-8 pt-8">
          <div className="col-lg-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={openNewFileModal}
              aria-label={intl.formatMessage({
                id: "SETTINGS.ACCOUNT.INSENTRICJS.CREATE"
              })}
            >
              <KTIcon iconName="plus" className="fs-2" />
              {intl.formatMessage({
                id: "SETTINGS.ACCOUNT.INSENTRICJS.CREATE"
              })}
            </button>
          </div>
        </div>
        <div className="row px-8 pb-8">
          <InsentricJSFiles />
        </div>
      </div>
      {itemIdForUpdate !== undefined && <InsentricJSModal />}
    </>
  );
};

const InsentricJsConfigurator = () => {
  return (
    <InsJSFilesProvider>
      <InsentricJsPage />
    </InsJSFilesProvider>
  );
};

export default InsentricJsConfigurator;
