import { useIntl } from "react-intl";

const GenSettingsHeaderLogo = () => {
  const intl = useIntl();
  return (
    <div className="card-header">
      <h2 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-1 mb-1">
          {intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.TITLE", defaultMessage: "Company Logo Settings" })}
        </span>
      </h2>
    </div>
  );
};

export default GenSettingsHeaderLogo;
