import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import { useIntl } from "react-intl";
import { useRBAC } from "../../../roles-permissions/core/RBACProvide";
import { toast } from "react-toastify";

type ActionsCopyProps = {
  handleOnClick: any;
  index: number;
  domain: string;
};

const ActionsCopy = ({ handleOnClick, index, domain }: ActionsCopyProps) => {
  const intl = useIntl();
  const { allowedUserButtonClick } = useRBAC();
  
  return (
    <div className="menu-item px-3">
      <button
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        onClick={handleOnClick}
        data-tooltip-id={`tooltip-btn-${index}`}
        aria-label={`Copy embed code for ${domain}`}
        disabled={!allowedUserButtonClick}
      >
        <KTIcon iconName="copy" iconType="outline" className="fs-2 m-0 actionIcon" aria-hidden="true" />
      </button>
      <Tooltip
        id={`tooltip-btn-${index}`}
        key={`tooltip-btn-${index}`}
        content={intl.formatMessage({
          id: "INSENTRICJS.EMBED"
        })}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export default ActionsCopy;
