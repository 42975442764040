import { useIntl } from "react-intl";
import { TextField } from "../../../../layout/components/form";

const GenSettingsCompanyName = ({ errors, touched }: { errors: any; touched: any }) => {
  const intl = useIntl();

  return (
    <div className="row">
      <div className="col-lg-4 p-3 mb-3">
        <span className="fw-bold fs-4 text-muted" id="company_name-label">
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.COMPANYNAME"
          })}
        </span>
      </div>
      <div className="col-lg-8 p-3 mb-3">
        <TextField
          id="company_name"
          label=""
          description=""
          placeholder={intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.COMPANYNAME.PLACEHOLDER"
          })}
          optional={false}
          classes={errors.company_name && touched.company_name ? "border-danger" : ""}
          isInvalid={false}
          ariaLabelledBy="company_name-label"
          ariaDescribedBy=""
        />
        {!!errors.company_name && touched.company_name ? (
          <p className="text-danger">
            {intl.formatMessage({
              id: errors.company_name
            })}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default GenSettingsCompanyName;
