export const exampleRolesData = [
  {
    id: "1ryh4trg",
    roleName: "Admin",
    description: "Administrator role",
    users: [
      {
        id: "1123",
        name: "John Doe",
        email: "john@email.com"
      },
      {
        id: "2345",
        name: "Jane Doe",
        email: "jane@email.com"
      },
      {
        id: "3456",
        name: "John Wick",
        email: "john.wick@email.com"
      }
    ],
    permissions: [
      {
        id: "1wsgf",
        name: "View Dashboard",
        description: "Can view dashboard",
        state: "active"
      },
      {
        id: "2fwe2",
        name: "View Reports",
        description: "Can view reports",
        state: "active"
      },
      {
        id: "4gwevr3",
        name: "View Users",
        description: "Can view users",
        state: "active"
      }
    ],
    actions: ["create", "read", "update", "delete"]
  },
  {
    id: "2tfhg",
    roleName: "User",
    description: "User role",
    users: [
      {
        id: "1i7765jjt",
        name: "John Doe 2",
        email: "john@email.com"
      },
      {
        id: "27tyhn",
        name: "Jane Doe 2",
        email: "jane@email.com"
      }
    ],
    permissions: [
      {
        id: "156yt",
        name: "View Dashboard",
        description: "Can view dashboard",
        state: "active"
      },
      {
        id: "254tegrht",
        name: "View Reports",
        description: "Can view reports",
        state: "active"
      },
      {
        id: "35eht",
        name: "View Users",
        description: "Can view users",
        state: "active"
      }
    ],

    actions: ["read"]
  },
];

export const examplePermissionsData = [
  {
    id: "1",
    name: "Permission 1",
    description: "Permission 1 description",
    state: "active"
  },
  {
    id: "2",
    name: "Permission 2",
    description: "Permission 2 description",
    state: "inactive"
  },
  {
    id: "3",
    name: "Permission 3",
    description: "Permission 3 description",
    state: "active"
  }
];
