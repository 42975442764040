import FormFieldParametersToken from "./FormFieldParametersToken";
import FormFieldParameter from "./FormFieldUrlParameter";
import FormFieldExcludedDomains from "./FormFieldExcludedDomains";
import FormFieldNonTemplatedEmails from "./FormFieldNonTemplatedEmails";
import FormFieldStartDate from "./FormFieldStartDate";
import FormFieldRunInTwoHours from "./FormFieldRunInTwoHours";

const FormFields = (props: any) => {
  return (
    <>
      <div className="mb-6">
        <FormFieldParametersToken
          errors={props.errors}
          touched={props.touched}
        />
      </div>
      <div className="mb-6">
        <FormFieldParameter errors={props.errors} touched={props.touched} />
      </div>
      <div className="mb-6">
        {" "}
        <FormFieldExcludedDomains
          errors={props.errors}
          touched={props.touched}
        />
      </div>
      <div className="mb-6">
        <FormFieldNonTemplatedEmails
          errors={props.errors}
          touched={props.touched}
          values={props.values}
          setFieldValue={props.setFieldValue}
        />
      </div>

      <div className="mb-6">
        <FormFieldRunInTwoHours
          errors={props.errors}
          touched={props.touched}
          values={props.values}
          setFieldValue={props.setFieldValue}
        />
      </div>

      <div className="mb-6">
        <FormFieldStartDate errors={props.errors} touched={props.touched} />
      </div>
    </>
  );
};

export default FormFields;
